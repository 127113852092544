const Utils = {
  // Function to format the date for display (DD-MM-YYYY)
  intoDDMMYYYY: (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  },

  // Function to format the date for input value (YYYY-MM-DD)
  intoYYYYMMDD: (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  },
  durationToSeconds: (duration) => {
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  },
  formatDate: (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  },
  generateTimeSlots: (start = '06:00', end = '23:59') => {
    const slots = [];
    let current = start;

    while (current !== end) {
      slots.push(current);
      const [hour, minute] = current.split(':').map(Number);

      // Calculate new time
      let newHour = hour;
      let newMinute = minute + 30; // Increment by 30 minutes

      // Handle minute overflow
      if (newMinute >= 60) {
        newMinute -= 60; // Reset minutes
        newHour += 1; // Increment hour
      }

      // Format new time
      current = `${String(newHour).padStart(2, '0')}:${String(newMinute).padStart(2, '0')}`;

      // Stop the loop if the new time exceeds the end time
      if (current > end) break;
    }

    // Add the final end time if it's not already included
    if (!slots.includes(end)) {
      slots.push(end);
    }

    return slots;
  },
  
  convertIsoTimeToParisTime: (isoString) => {
    // Create a Date object from the ISO string
    const date = new Date(isoString);

    // Define options for formatting the date to Paris timezone
    const options = {
      timeZone: 'Europe/Paris',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };

    // Format the date to Paris timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const parisTime = formatter.format(date);

    // Split the formatted string into date and time parts
    const [day, month, year, hour, minute, second] = parisTime.match(/\d+/g);

    // Return the formatted string in the desired format
    return `${hour}:${minute}h`;
  },
  convertIsoDateToParisDate: (isoString) => {
    // Create a Date object from the ISO string
    const date = new Date(isoString);

    // Define options for formatting the date to Paris timezone
    const options = {
      timeZone: 'Europe/Paris',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };

    // Format the date to Paris timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const parisTime = formatter.format(date);

    // Split the formatted string into date and time parts
    const [day, month, year, hour, minute, second] = parisTime.match(/\d+/g);

    // Return the formatted string in the desired format
    return `${day}-${month}-${year}`;
  },
  formatFileSize: (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    if (sizeInKB > 1024) {
      const sizeInMB = sizeInKB / 1024;
      return `${sizeInMB.toFixed(2)} MB`;
    }
    return `${sizeInKB.toFixed(2)} KB`;
  },
  convertTimeToLocalTimeZone: (time) => {
    if (!time) return "";

    // Split the input time (HH:MM)
    const [hours, minutes] = time.split(':').map(Number);

    // Get the current date
    const date = new Date();

    // Set the provided hours and minutes to the current date
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    // Get the user's local time zone
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Format the date to the system's local time zone
    const formatter = new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: localTimeZone,
      hour12: false,
    });

    // Convert the date to the local time zone
    const localTime = formatter.format(date);

    // Return the formatted time in HH:MM format
    const [formattedHours, formattedMinutes] = localTime.match(/\d+/g);
    return `${formattedHours}:${formattedMinutes}`;
  },
  convertUTCToLocalTimeZone: (utcTime) => {
    // Create a date object for today in UTC with the given time
    const [hours, minutes] = utcTime.split(':').map(Number);
    const date = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), hours, minutes));

    // Format the date to the local time zone
    const formatter = new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Local system time zone
    });

    const localTime = formatter.format(date);
    return localTime;
  },
  convertUTCToLocalDate: (isoString) => {
    const date = new Date(isoString); // Create a Date object from the UTC ISO string
    const options = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Get the user's local time zone
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    // Get the date components using toLocaleDateString
    const [day, month, year] = date.toLocaleDateString('en-GB', options).split('/');

    // Return the date in DD-MM-YYYY format
    return `${day}-${month}-${year}`;
  },
  getLocalTimeZoneInfo: () => {
    // Get the full timezone name (like "Europe/Paris")
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Get the current date to calculate the timezone offset and abbreviation
    const now = new Date();

    // Get the timezone offset in hours and minutes
    const timeZoneOffset = -now.getTimezoneOffset(); // in minutes, negate for positive offsets
    const hours = Math.floor(timeZoneOffset / 60);
    const minutes = timeZoneOffset % 60;

    // Format offset as "+02:00"
    const formattedOffset = `${hours >= 0 ? '+' : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    // Get timezone abbreviation (like "CEST")
    const formatter = new Intl.DateTimeFormat('en-EU', {
      timeZoneName: 'short'
    });

    // Find timezone abbreviation (e.g., "CEST")
    const abbreviation = formatter.formatToParts(now).find(part => part.type === 'timeZoneName').value;

    // Return the details separately
    return {
      timeZone,              // "Europe/Paris"
      abbreviation,          // "CEST"
      formattedOffset        // "+02:00"
    };
  }

};



export default Utils;
