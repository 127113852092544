import axiosInstance from '../axiosConfig'; // Import the configured Axios instance

const API_URL =
  `${process.env.REACT_APP_API_BASE_URL}/collaboration-api/collaborator`;

const collaboratorService = {
  getAll: async (limit, offset) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/?limit=${limit}&offset=${offset}`
      );
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Failed to fetch collaborators. Please try again.");
    }
  },

  get: ({ rdv_id }) => {
    // You may need to implement logout functionality depending on your Django setup.
    throw new Error("Not implemented");
  },

  update: ({ rdv_id, obj }) => {
    // You may need to implement logout functionality depending on your Django setup.
    throw new Error("Not implemented");
  },

  softDelete: ({ rdv_id }) => {
    // You may need to implement logout functionality depending on your Django setup.
    throw new Error("Not implemented");
  },
};

export default collaboratorService;
