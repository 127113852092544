import { useState, Suspense } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Textarea,
  SelectField,
  FormHelperText,
  Select,
  Divider,
  CircularProgress,
  FormErrorMessage,
} from "@chakra-ui/react";

// Language Management
import { useTranslation } from "react-i18next";

// Import axios
import axios from "axios";

// Service
import rdvService from "services/rdvService";
import { toast } from "react-toastify";
import Utils from "services/Utils";

const CCompleteRequest = ({ isOpen, onClose, accessToken, originalRdvId }) => {
  // Init translation
  const { t } = useTranslation();
  const timeZoneInfo = Utils.getLocalTimeZoneInfo().timeZone;

  // State for form fields
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subject, setSubject] = useState("");
  const [issue, setIssue] = useState("");
  const [closingRemarks, setClosingRemarks] = useState("");

  //   In Progress
  const [saving, setSaving] = useState(false);


  // Validation messages
  const [errors, setErrors] = useState({});

  // Function to validate the form fields
  const validateForm = () => {
    const newErrors = {};

    if (!subject) {
      newErrors.subject = t("subject_required");
    }
    if (!startDate) {
      newErrors.startDate = t("start_date_required");
    }
    if (!endDate) {
      newErrors.endDate = t("end_date_required");
    }
    if (new Date(startDate) >= new Date(endDate)) {
      newErrors.dateRange = t("end_date_must_be_after_start_date");
    }
    if (!issue) {
      newErrors.issue = t("issue_required");
    }
    if (!closingRemarks) {
      newErrors.closingRemarks = t("closing_remarks_required");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    setSaving(true);
    const payload = {
      subject_for_mediation: subject,
      start_date: startDate,
      end_date: endDate,
      issue,
      closing_remarks: closingRemarks,
      appointment: originalRdvId,
      subject,
      is_closed: true,
      status: "Completed",
      timezone: timeZoneInfo,
    };

    // console.log("the payload", payload);

    try {
      const response = await rdvService.completReq({
        Id: originalRdvId,
        obj: payload,
        accessToken,
      });

      // Check if the response status is 200
      if (response.status === 200) {
        // Show success toast
        toast.success(`${t("status_updated")}`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // console.log('Request successful:', response.data);
      } else {
        // Show error toast
        toast.error(`${t("messages.failed_to_complete_request")}`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.error(response.error); // Log the error for debugging
      }

    } catch (err) {
      // Handle unexpected errors
      console.error('Unexpected error:', err);
      toast.error(`${t("messages.failed_to_complete_request")}`, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setSaving(false); // Ensure saving state is reset regardless of success or failure
      // onClose(); // Close the modal or perform any cleanup if needed
    }
  };

  // Function to update RDV
  const updateRdv = async ({ payload }) => {
    // Update the RDV
    await rdvService.update({
      pk: originalRdvId, // Primary key of the RDV
      obj: payload,
      accessToken,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("admin__appointments__complete_req")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Subject */}
          <FormControl isInvalid={!!errors.subject}>
            <FormLabel>{t("admin__appointments_table__subject")}</FormLabel>
            <Select
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            >
              <option value='' disabled>
                {t("select_option")}
              </option>
              <option value={t("decision support")}>
                {t("family_topic__decision_support")}
              </option>
              <option
                value={t("custody arrangement coparenting")}
              >
                {t("family_topic__custody_arrangement_coparenting")}
              </option>
              <option
                value={t("education maintenance contribution")}
              >
                {t("family_topic__education_maintenance_contribution")}
              </option>
              <option value={t("other miscellaneous situations")}>
                {t("family_topic__other_miscellaneous_situations")}
              </option>
              <option value={t("same sex family")}>
                {t("family_topic__same_sex_family")}
              </option>
              <option value={t("blended family")}>
                {t("family_topic__blended_family")}
              </option>
              <option value={t("siblings")}>
                {t("family_topic__siblings")}
              </option>
              <option
                value={t("grandparents children grandchildren")}
              >
                {t("family_topic__grandparents_children_grandchildren")}
              </option>
              <option value={t("parents teenagers")}>
                {t("family_topic__parents_teenagers")}
              </option>
              <option value={t("parents young adults")}>
                {t("family_topic__parents_young_adults")}
              </option>
              <option value={t("separation divorce")}>
                {t("family_topic__separation_divorce")}
              </option>
            </Select>
          </FormControl>

          {/* Start Date */}
          <FormControl mt={4}>
            <FormLabel>{t("select_rdv_start_date")}</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <FormHelperText>
              {startDate && <Text color="green.500">{t("date_selected")}: {startDate}</Text>}
            </FormHelperText>
          </FormControl>

          {/* End Date */}
          <FormControl mt={4}>
            <FormLabel>{t("select_rdv_end_date")}</FormLabel>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <FormHelperText>
              {endDate && <Text color="green.500">{t("date_selected")}: {endDate}</Text>}
            </FormHelperText>
          </FormControl>

          {/* Issue */}
          <FormControl mt={4}>
            <FormLabel>{t("select_issue")}</FormLabel>
            <Select value={issue} onChange={(e) => setIssue(e.target.value)}>
              <option value='' disabled>
                {t("select_option")}
              </option>
              <option value="written_agreement">
                {t("written_agreement")}
              </option>
              <option value="oral agreement">{t("oral_agreement")}</option>
              <option value="partial written agreement">
                {t("partial_written_agreement")}
              </option>
              <option value="significant progress conflict resolution">
                {t("significant_progress_conflict_resolution")}
              </option>
              <option value="interruption initiated by users">
                {t("interruption_initiated_by_users")}
              </option>
              <option value="interruption initiated by mediator">
                {t("interruption_initiated_by_mediator")}
              </option>
              <option value="no_agreement">{t("no_agreement")}</option>
            </Select>
          </FormControl>

          {/* Closing Remarks */}
          <FormControl mt={4}>
            <FormLabel>{t("closing_remarks")}</FormLabel>
            <Textarea
              value={closingRemarks}
              type="text"
              onChange={(e) => setClosingRemarks(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            fontSize={"16px"}
            color={"white"}
            backgroundColor={"#EE6464"}
            mr={3}
            onClick={handleSubmit}>
            {saving ? (
              <CircularProgress isIndeterminate size='30px' color="#ffffff" />
            ) : (
              t("admin__appointments__complete_req")
            )}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// export default CreateFirstSessionDialog;
// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function CompleteRequest({
  isOpen,
  onClose,
  accessToken,
  originalRdvId,
}) {
  return (
    <Suspense fallback="loading">
      <CCompleteRequest
        isOpen={isOpen}
        onClose={onClose}
        accessToken={accessToken}
        originalRdvId={originalRdvId}
      />
    </Suspense>
  );
}
