import axiosInstance from '../axiosConfig'; // Import the configured Axios instance

const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api`;

const locationService = {
  getAll: async (associationId) => {
    try {
      // Attach the access token to the Authorization header
      const response = await axiosInstance.get(`${API_URL}/${associationId ? `${associationId}/` : ''}location/`);
      // Log
      // console.log("Location data: ", response.data);
      // Return data
      return {
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
};

export default locationService;
