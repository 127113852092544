import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
  Icon,
  FormErrorMessage,
  HStack
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { selectLocations } from "selectors/authSelector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import rdvSessionService from "services/rdvSessionService";
import { enUS, fr } from "date-fns/locale";
import { startOfMonth, endOfMonth, subMonths, addMonths } from "date-fns";
import "../../../../assets/css/App.css";
import rdvService from "services/rdvService";
import { selectAssociation } from "selectors/authSelector";
import { selectAccessToken } from "selectors/authSelector";
import locationService from "services/locationService";
import Utils from "services/Utils";

const NewRequestForm = ({ onSubmit, onClose, locationsList, servicesList }) => {
  const accessToken = useSelector(selectAccessToken);
  const association = useSelector(selectAssociation);
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch();
  // const locationsList = useSelector(selectLocations);
  const datePickerRef = useRef(null);

  const getDateLocale = () => {
    switch (i18n.language) {
      case "fr":
        return fr;
      default:
        return enUS;
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    status: "",
    sex: "",
    city: "",
    email: "",
    phone: "",
    additionalParticipant1: "",
    additionalParticipant2: "",
    additionalDetails: "",
    date: new Date(),
    time: "",
    service: "",
    location: "",
    consultation_mode: "",
    service_themes: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    time: "",
    status: "",
    sex: "",
    service: "",
    location: "",
    consultation_mode: "",
    service_themes: "",
  });
  // console.log(formData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [monthAvailability, setMonthAvailability] = useState({});
  const [disabledDates, setDisabledDates] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesThemes, setSelectedServicesThemes] = useState([]);
  const [consultationModesList, setConsultationModesList] = useState([]);
  const [isLocationDisabled, setIsLocationDisabled] = useState(false);


  useEffect(() => {
    if (formData.location && formData.service) {
      fetchMonthAvailability(
        formData.location,
        formData.service,
        formData.date,
      );
    }
  }, [formData.location, formData.service]);

  useEffect(() => {
    if (association) {
      HandleGetServicesList(association.slug);
      HandleGetConsultationModeList(association.slug);
    }

    console.log("servicesList", servicesList.consultation_specialities)
  }, [association]);

  const HandleGetServicesList = async (slug) => {
    setSelectedServices(servicesList?.consultation_specialities)
    // try {
    //   const res = await rdvService.getServices(accessToken, slug);
    //   // console.log("services response", res.data.results);

    //   // Set the selected services only if results exist
    //   if (Array.isArray(res.data.results)) {
    //     setSelectedServices(res.data.results);
    //   } else {
    //     console.error("No results found in the services response.");
    //   }
    // } catch (error) {
    //   console.error("Error fetching services:", error);
    //   // Optionally, you can set an error state or show a notification to the user
    //   // setError("Failed to fetch services. Please try again later.");
    // }
  }

  useEffect(() => {
    console.log("*** selected service_themes **", formData.service_themes);
  }, [formData.service_themes])


  const HandleGetConsultationModeList = async (slug) => {

    try {
      const res = await rdvService.getConsultationMode(accessToken, slug);
      console.log("consultation mode response", res.data);

      // Set the selected services only if results exist
      if (Array.isArray(res.data)) {
        setConsultationModesList(res.data);
      } else {
        console.error("No results found in the consultation mode response.");
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    } 
  }

  const fetchMonthAvailability = async (locationId, serviceId, date) => {
    const startDate = startOfMonth(subMonths(date, 1))
      .toISOString()
      .split("T")[0];
    const endDate = endOfMonth(addMonths(date, 1)).toISOString().split("T")[0];
    let response
    try {
      if (isLocationDisabled) {
        response = await rdvSessionService.getByMonthAvailability({
          slug: association.slug,
          serviceId,
          fromDate: startDate,
          type: 'Info',
          toDate: endDate,
        });
      }
      response = await rdvSessionService.getByMonthAvailability({
        slug: association.slug,
        locationId,
        serviceId,
        fromDate: startDate,
        type: 'Info',
        toDate: endDate,
      });

      setMonthAvailability(response || {});

      // Calculate disabled dates
      const emptyDates = Object.entries(response)
        .filter(([_, events]) => events.length === 0)
        .map(([date, _]) => new Date(date));

      setDisabledDates(emptyDates);
      // console.log(formData);
    } catch (error) {
      console.error("Error fetching month availability", error);
      setMonthAvailability({});
      setDisabledDates([]);
    }
  };

  const isDateDisabled = (date) => {
    return disabledDates.some(
      (disabledDate) =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate(),
    );
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^(\+[0-9]{1,3}|0)[0-9]{9,10}$/;
    return re.test(String(phone));
  };

  const validateName = (name) => {
    const re = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s\-']+$/;
    return re.test(String(name));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "consultation_mode") {
      const selectedMode = consultationModesList.find((mode) => mode.id === Number(value));
      // Set the disabled state for location based on is_location_agnostic
      const isLocationAgnostic = selectedMode?.is_location_agnostic || false;
      setIsLocationDisabled(isLocationAgnostic);

      // If location-agnostic, reset the location field
      setFormData((prev) => ({
        ...prev,
        [name]: value, // Update consultation mode
        ...(isLocationAgnostic && { location: "" }), // Reset location if location-agnostic
      }));

      // Reset the location error if consultation mode changes
      setErrors((prev) => ({
        ...prev,
        location: "", // Clear the location error
      }));

      console.log("setted update", selectedMode, value)
    }

    if (name === "service") {
      const matchedServices = servicesList?.consultation_specialities.filter(
        (data) => data.id === Number(e.target.value)
      );
      setFormData((prev) => ({
        ...prev,
        service_themes: '',
      }));
      console.log("matchedServices", matchedServices)
      setSelectedServicesThemes(matchedServices);
    }


    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "location" && { time: "", date: new Date() }) // Reset time when location changes
    }));

    if (name === "name") {
      if (!value.trim()) {
        setErrors((prev) => ({
          ...prev,
          name: t("new_request_form.name_required"),
        }));
      } else if (!validateName(value)) {
        setErrors((prev) => ({
          ...prev,
          name: t("new_request_form.name_invalid"),
        }));
      } else {
        setErrors((prev) => ({ ...prev, name: "" }));
      }
    } else if (name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: validateEmail(value) ? "" : t("new_request_form.email_invalid"),
      }));
    } else if (name === "phone") {
      setErrors((prev) => ({
        ...prev,
        phone: validatePhone(value)
          ? ""
          : t("new_request_form.phone_number_invalid"),
      }));
    } else if (name === "status") {
      setErrors((prev) => ({
        ...prev,
        status: value ? "" : t("new_request_form.status_required"),
      }));
    } else if (name === "sex") {
      setErrors((prev) => ({
        ...prev,
        sex: value ? "" : t("new_request_form.sex_required"),
      }));
    } else if (name === "service") {
      setErrors((prev) => ({
        ...prev,
        service: value ? "" : t("new_request_form.service_required"),
      }));
    } else if (name === "service_themes") {
      setErrors((prev) => ({
        ...prev,
        service_themes: value ? "" : t("new_request_form.service_themes_required"),
      }));
    } else if (name === "location" && isLocationDisabled) {
      setErrors((prev) => ({
        ...prev,
        location: value ? "" : t("new_request_form.location_required"),
      }));
      handleMonthChange(new Date());
    } else if (name === "consultation_mode") {
      setErrors((prev) => ({
        ...prev,
        consultation_mode: value ? "" : t("new_request_form.consultation_mode_required"),
      }));
    }
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, date }));
    if (date) {
      setErrors((prev) => ({ ...prev, date: "" }));
      // Fetch time slots for the selected date
      fetchTimeSlots(formData.location, date);
    }
  };

  const fetchTimeSlots = async (locationId, date) => {
    const formattedDate = formatDate(date);
    const availableSlots = monthAvailability[formattedDate] || [];
    setTimeSlots(availableSlots);
    // console.log(formData);
  };

  const handleTimeChange = (e) => {
    const selectedIndex = parseInt(e.target.value);

    if (selectedIndex >= 0 && selectedIndex < timeSlots.length) {
      const selectedSlot = timeSlots[selectedIndex];
      setSelectedSlot(selectedSlot);
      setFormData((prev) => ({ ...prev, time: selectedSlot.start_time }));
      if (selectedSlot.start_time) {
        setErrors((prev) => ({ ...prev, time: "" }));
      }
    } else {
      // Reset the time if the placeholder or an invalid option is selected
      setSelectedSlot(null);
      setFormData((prev) => ({ ...prev, time: "" }));
    }
  };

  const handleMonthChange = (date) => {
    console.log("the date ", date, formData.location, formData.service)
    if (formData.location && formData.service) {
      fetchMonthAvailability(formData.location, formData.service, date);
    }
  };

  useEffect(() => {
    // console.log("formData.location, formData.service, new Date()", formData.location, formData.service, new Date())
    fetchMonthAvailability(formData.location, formData.service, new Date());

  }, [formData.location, formData.service, formData.consultation_mode]);

  const formatDate = (date) => {
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = t("new_request_form.name_required");
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = t("new_request_form.email_required");
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = t("new_request_form.email_invalid");
      isValid = false;
    }
    if (!formData.phone.trim()) {
      newErrors.phone = t("new_request_form.phone_number_required");
      isValid = false;
    } else if (!validatePhone(formData.phone)) {
      newErrors.phone = t("new_request_form.phone_number_invalid");
      isValid = false;
    }
    if (!formData.date) {
      newErrors.date = t("new_request_form.date_required");
      isValid = false;
    }
    if (!formData.time) {
      newErrors.time = t("new_request_form.time_required");
      isValid = false;
    }
    if (!formData.status) {
      newErrors.status = t("new_request_form.status_required");
      isValid = false;
    }
    if (!formData.sex) {
      newErrors.sex = t("new_request_form.sex_required");
      isValid = false;
    }
    if (!formData.service) {
      newErrors.service = t("new_request_form.service_required");
      isValid = false;
    }
    // Only validate location if isLocationDisabled is false
    if (!isLocationDisabled && !formData.location) {
      newErrors.location = t("new_request_form.location_required");
      isValid = false;
    }
    // Validate consultation_mode
    if (!formData.consultation_mode) {
      newErrors.consultation_mode = t("new_request_form.consultation_mode_required");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    // console.log(selectedSlot);
    if (validateForm()) {
      const formattedData = {
        ...formData,
        date: formatDate(formData.date),
        tentative_availability_slot: selectedSlot.id,
      };
      // console.log("formData", formData);
      onSubmit(formattedData);
    }
  };

  const isSubmitDisabled =
    !formData.name ||
    !formData.email ||
    !formData.phone ||
    !formData.date ||
    !formData.time ||
    !formData.status ||
    !formData.sex ||
    !formData.service ||
    !formData.location ||
    Object.values(errors).some((error) => error !== "");

  return (
    <Box borderRadius="md" width={"100%"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <FormControl id="consultation_mode"
          isRequired
          isInvalid={isSubmitted && !!errors.consultation_mode}
        >
          <FormLabel>{t("consultation_mode")}</FormLabel>
          <Select
            name="consultation_mode"
            value={formData.consultation_mode}
            onChange={handleInputChange}
            placeholder={t("select_consultation_mode")}
            bg="gray.100"
          >
            {consultationModesList.map((mode) => (
              <option key={mode.id} value={mode.id}>
                {i18n.language === 'fr' ? mode.name_fr : mode.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.consultation_mode}</FormErrorMessage>
        </FormControl>

        <FormControl id="location"
          isRequired
          isInvalid={isSubmitted && !!errors.location}
        >
          <FormLabel>{t("location")}</FormLabel>
          <Select
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder={t("select_location")}
            bg="gray.100"
            isDisabled={isLocationDisabled}
          >
            {locationsList.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.location}</FormErrorMessage>
        </FormControl>

        <FormControl id="service"
          isRequired
          isInvalid={isSubmitted && !!errors.service}
        >
          <FormLabel>{t("admin__appointments_table__service")}</FormLabel>
          <Select
            name="service"
            value={formData.service}
            onChange={handleInputChange}
            placeholder={t("select_service")}
            bg="gray.100"
          >
            {selectedServices.map((data, index) => (
              <option value={data.id} key={index}>{i18n.language === 'fr' ? data.name_fr : data.name}</option>
            ))}

          </Select>
          <FormErrorMessage>{errors.service}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="service_themes"
          isRequired
          isInvalid={isSubmitted && !!errors.service_themes}
        >
          <FormLabel>{t("admin__appointments_table__service")} Themes</FormLabel>
          <Select
            name="service_themes"
            value={formData.service_themes}
            onChange={handleInputChange}
            placeholder={t("select_service_themes")}
            bg="gray.100"
          >
            {selectedServicesThemes[0]?.concerns.map((data, index) => (
              <option value={data.id} key={index}>{i18n.language === 'fr' ? data.name_fr : data.name}</option>
            ))}

          </Select>
          <FormErrorMessage>{errors.service_themes}</FormErrorMessage>
        </FormControl>

        {/* <FormControl id="status" isRequired isInvalid={!!errors.status}>
          <FormLabel>{t("new_request_form.status")}</FormLabel>
          <Select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            placeholder={t("new_request_form.status")}
            bg="gray.100"
          >
            <option value="mother">{t("new_request_form.mother")}</option>
            <option value="father">{t("new_request_form.father")}</option>
            <option value="brother">{t("new_request_form.brother")}</option>
            <option value="sister">{t("new_request_form.sister")}</option>
            <option value="grandmother">
              {t("new_request_form.grandmother")}
            </option>
            <option value="grandfather">
              {t("new_request_form.grandfather")}
            </option>
            <option value="grandparent">
              {t("new_request_form.grandparent")}
            </option>
            <option value="grandson">{t("new_request_form.grandson")}</option>
            <option value="granddaughter">
              {t("new_request_form.granddaughter")}
            </option>
            <option value="grandchild">
              {t("new_request_form.grandchild")}
            </option>
            <option value="uncle">{t("new_request_form.uncle")}</option>
            <option value="aunt">{t("new_request_form.aunt")}</option>
            <option value="neice">{t("new_request_form.neice")}</option>
            <option value="nephew">{t("new_request_form.nephew")}</option>
            <option value="cousin">{t("new_request_form.cousin")}</option>
            <option value="sister_in_law">
              {t("new_request_form.sister_in_law")}
            </option>
            <option value="brother_in_law">
              {t("new_request_form.brother_in_law")}
            </option>
            <option value="mother_in_law">
              {t("new_request_form.mother_in_law")}
            </option>
            <option value="father_in_law">
              {t("new_request_form.father_in_law")}
            </option>
            <option value="fiance">{t("new_request_form.fiance")}</option>
            <option value="friend">{t("new_request_form.friend")}</option>
          </Select>
          <FormErrorMessage>{errors.status}</FormErrorMessage>
        </FormControl> */}
      </SimpleGrid>

      <FormControl paddingTop="4" id="name" isRequired isInvalid={!!errors.name}>
        <FormLabel>{t("new_request_form.name")}</FormLabel>
        <Input
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder={t("new_request_form.name")}
          bg="gray.100"
        />
        <FormErrorMessage>{errors.name}</FormErrorMessage>
      </FormControl>

      <FormControl paddingY="4" id="status" isRequired isInvalid={!!errors.status}>
        <FormLabel>{t("new_request_form.status")}</FormLabel>
        <Select
          name="status"
          value={formData.status}
          onChange={handleInputChange}
          placeholder={t("new_request_form.status")}
          bg="gray.100"
        >
          <option value="mother">{t("new_request_form.mother")}</option>
          <option value="father">{t("new_request_form.father")}</option>
          <option value="brother">{t("new_request_form.brother")}</option>
          <option value="sister">{t("new_request_form.sister")}</option>
          <option value="grandmother">
            {t("new_request_form.grandmother")}
          </option>
          <option value="grandfather">
            {t("new_request_form.grandfather")}
          </option>
          <option value="grandparent">
            {t("new_request_form.grandparent")}
          </option>
          <option value="grandson">{t("new_request_form.grandson")}</option>
          <option value="granddaughter">
            {t("new_request_form.granddaughter")}
          </option>
          <option value="grandchild">
            {t("new_request_form.grandchild")}
          </option>
          <option value="uncle">{t("new_request_form.uncle")}</option>
          <option value="aunt">{t("new_request_form.aunt")}</option>
          {/* <option value="neice">{t("new_request_form.neice")}</option> */}
          <option value="nephew">{t("new_request_form.nephew")}</option>
          <option value="cousin">{t("new_request_form.cousin")}</option>
          <option value="sister_in_law">
            {t("new_request_form.sister_in_law")}
          </option>
          <option value="brother_in_law">
            {t("new_request_form.brother_in_law")}
          </option>
          <option value="mother_in_law">
            {t("new_request_form.mother_in_law")}
          </option>
          <option value="father_in_law">
            {t("new_request_form.father_in_law")}
          </option>
          <option value="fiance">{t("new_request_form.fiance")}</option>
          <option value="friend">{t("new_request_form.friend")}</option>
        </Select>
        <FormErrorMessage>{errors.status}</FormErrorMessage>
      </FormControl>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <FormControl id="sex" isRequired isInvalid={!!errors.sex}>
          <FormLabel>{t("new_request_form.sex")}</FormLabel>
          <Select
            name="sex"
            value={formData.sex}
            onChange={handleInputChange}
            placeholder={t("new_request_form.sex")}
            bg="gray.100"
          >
            <option value="M">{t("new_request_form.male")}</option>
            <option value="F">{t("new_request_form.female")}</option>
          </Select>
          <FormErrorMessage>{errors.sex}</FormErrorMessage>
        </FormControl>

        <FormControl id="city">
          <FormLabel>{t("new_request_form.city")}</FormLabel>
          <Input
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            placeholder={t("new_request_form.city")}
            bg="gray.100"
          />
        </FormControl>

        <FormControl id="email" isRequired isInvalid={!!errors.email}>
          <FormLabel>{t("new_request_form.email")}</FormLabel>
          <Input
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            type="email"
            placeholder={t("new_request_form.email")}
            bg="gray.100"
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>

        <FormControl id="phone" isRequired isInvalid={!!errors.phone}>
          <FormLabel>{t("new_request_form.phone_number")}</FormLabel>
          <Input
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            type="tel"
            placeholder={t("new_request_form.phone_number")}
            bg="gray.100"
          />
          <FormErrorMessage>{errors.phone}</FormErrorMessage>
        </FormControl>

        <FormControl id="date" isRequired isInvalid={!!errors.date}>
          <FormLabel>{t("date")}</FormLabel>
          <InputGroup
            display="flex"
            border="1px solid #E2E8F0"
            borderRadius="md"
            bg="gray.100"
            alignItems="center"
          >
            <DatePicker
              disabled={!formData.service}
              selected={formData.date || null}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="chakra-input"
              showPopperArrow={false}
              placeholderText={t("no_date_selected")}
              minDate={new Date()}
              ref={datePickerRef}
              filterDate={(date) => !isDateDisabled(date)}
              onMonthChange={handleMonthChange}
              locale={getDateLocale()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={
                <Input
                  variant="unstyled"
                  padding="8px 12px"
                  bg="gray.100"
                  borderRadius="md"
                  placeholder={t("no_date_selected")}
                />
              }
            />
            <InputRightElement
              pointerEvents="all"
              top="50%"
              transform="translateY(-50%)"
              onClick={
                (!isLocationDisabled && !formData.location) || !formData.service
                  ? null // Disable the click if either location (when applicable) or service is missing
                  : () => datePickerRef.current.setOpen(true)
              }
            >
              <Icon as={FaCalendarAlt} color="gray.500" />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.date}</FormErrorMessage>
        </FormControl>

        <FormControl id="time" isRequired isInvalid={!!errors.time}>
          <FormLabel>{t("start_time")}</FormLabel>
          <Select
            value={
              formData.time
                ? timeSlots.findIndex(
                  (slot) => slot.start_time === formData.time,
                )
                : ""
            }
            onChange={handleTimeChange}
            bg="gray.100"
            isDisabled={timeSlots.length === 0}
          >
            {timeSlots.length > 0 ? (
              <>
                {console.log("timeSlots", timeSlots)}
                <option value="">{t("select_time")}</option>
                {timeSlots.map((slot, index) => (
                  <option key={index} value={index}>
                    {`${Utils.convertUTCToLocalTimeZone(slot.start_time)}`}
                  </option>
                ))}
              </>
            ) : (
              <option value="">{t("no_time_slots_available")}</option>
            )}
          </Select>
          <FormErrorMessage>{errors.time}</FormErrorMessage>
        </FormControl>

        <FormControl id="additional-participant1">
          <FormLabel>
            {t("new_request_form.additional_participant_1")}
          </FormLabel>
          <Input
            name="additionalParticipant1"
            value={formData.additionalParticipant1}
            onChange={handleInputChange}
            placeholder={t("new_request_form.additional_participant_1")}
            bg="gray.100"
          />
        </FormControl>

        <FormControl id="additional-participant2">
          <FormLabel>
            {t("new_request_form.additional_participant_2")}
          </FormLabel>
          <Input
            name="additionalParticipant2"
            value={formData.additionalParticipant2}
            onChange={handleInputChange}
            placeholder={t("new_request_form.additional_participant_2")}
            bg="gray.100"
          />
        </FormControl>
      </SimpleGrid>

      <FormControl id="additional-details" mt={4}>
        <FormLabel>{t("new_request_form.additional_details")}</FormLabel>
        <Textarea
          name="additionalDetails"
          value={formData.additionalDetails}
          onChange={handleInputChange}
          placeholder={t("new_request_form.additional_details")}
          bg="gray.100"
        />
      </FormControl>

      <HStack spacing={4} justify="flex-end" width="100%" my={2}>
        <Button
          sx={{ backgroundColor: "#ff6666", color: "white", _hover: { backgroundColor: "#e65c5c" } }} // Customize hover color if needed
          onClick={handleSubmit}
        >
          {t("submit")}
        </Button>

        <Button
          onClick={onClose}
          ml={3}
        >
          {t("cancel")}
        </Button>
      </HStack>
    </Box >
  );
};

export default NewRequestForm;
