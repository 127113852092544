import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  FormControl,
  Button,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import rdvSessionService from "services/rdvSessionService";
import "trix/dist/trix.css";
import "trix";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCheck, FaTimes } from "react-icons/fa";

const SessionCommantsTab = ({ isClosed, data, accessToken, onStateChange }) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = editorRef.current;

    const handleEditorChange = (event) => {
      const htmlContent = event.target.innerHTML;
      setNewComment(htmlContent);
    };

    if (editor) {
      editor.addEventListener("trix-change", handleEditorChange);

      // Clean up event listener on component unmount
      return () => {
        editor.removeEventListener("trix-change", handleEditorChange);
      };
    }
  }, []);

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      return; // Don't proceed if the comment is empty
    }

    try {
      await rdvSessionService.addComment({
        pk: data.id,
        obj: {
          comment: newComment,
        },
        accessToken,
      });

      toast.success(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaCheck style={{ marginRight: '8px' }} />
          {t('messages.changesSaved')}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "#FF6666",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          icon: false
        }
      );
      onStateChange();

      setNewComment("");
      editorRef.current.editor.loadHTML("");
    } catch (error) {
      toast.error(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t('messages.failedToAddComment')}
        </div>,
        {
          position: "bottom-center",
          style: {
            backgroundColor: "red",
            color: "white",
            width: 'auto',
            minWidth: '400px'
          },
          progressBar: false,
          icon: false
        }
      );
    }
  };

  return (
    <Box width={"100%"} height={"auto"} >
      <FormControl mt={4} width={"100%"}>
        <div>
          <input id="trix-input" type="hidden" name="content" />
          <trix-editor input="trix-input" ref={editorRef} ></trix-editor>
        </div>
        <Button
          mt={4}
          colorScheme="red"
          onClick={handleAddComment}
          isDisabled={!newComment.trim() || isClosed.is_closed}

        >
          {t("admin__appointments__save_comment")}
        </Button>
      </FormControl>
      {Array.isArray(data?.thread) && data?.thread?.length > 0 && (
        <VStack mt={8} spacing={4} align="stretch">
          {data.thread.map((threadItem, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
              <HStack justify="space-between">
                <Text fontWeight="bold">{threadItem.commented_by.name}</Text>
                <Text color="gray.500" fontSize="sm">
                  {new Date(threadItem.commented_on).toLocaleString('fr', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Text>
              </HStack>
              <Box mt={2} dangerouslySetInnerHTML={{ __html: threadItem.comment }} />
            </Box>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export default SessionCommantsTab;
