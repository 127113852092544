import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import {
  Flex,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Spinner,
  Skeleton,
  Select,
  Circle,
  IconButton,
  Image, Text, ModalOverlay, ModalHeader, ModalBody, ModalContent, Modal, FormLabel, Input, HStack, FormControl,
  ModalCloseButton,
  Tooltip
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import { debounce } from 'lodash';
import rdvService from "services/rdvService";
import Card from "components/card/Card";
import SearchAndFilter from "./components/SearchAndFilter";
import NewRequestForm from "./components/NewRequestForm";
import { useHistory } from "react-router-dom";
import BrandHeader from "components/menu/BrandHeader";
import { connect } from "react-redux";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
  selectLocations,
  selectIsAuthenticated,
  selectProfessionalProfile
} from "selectors/authSelector";
import { logout } from 'actions/authActions';
import {
  getData,
  getError,
  getLoading,
} from "../../../selectors/collaboratorSelector";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../actions/collaboratorActions.js";
import Utils from "services/Utils";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import i18n from "i18n";
import ToFirstPage from "../../../assets/img/dashboards/first.svg";
import ToLastPage from "../../../assets/img/dashboards/last.svg";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCheckCircle } from "react-icons/fa";

registerLocale("fr", fr);

const selectStyles = {
  backgroundColor: "rgba(86, 86, 86, 15%)",
  borderRadius: "12px",
  color: "gray.900",
  marginLeft: "10px",
};

const datePickerStyles = {
  ...selectStyles,
  padding: "8px 12px",
  border: "none",
  width: "150px",
  marginRight: "10px",
  _placeholder: { color: "black", fontSize: "" },
};

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

function AppointmentStatusLabel(appointment, t) {
  let status = '';
  let statusColor = "#199CD6";
  let statusBgColor = "#E4F5FC";

  switch (appointment) {
    case "Scheduled":
      status = t("status_options.scheduled");
      statusColor = "#FF6666";
      statusBgColor = "#FFEEEE";
      break;
    case "Occurred":
      status = t("status_options.occurred");
      statusColor = "#9FB80A";
      statusBgColor = "#F7F9ED";
      break;
    case "Cancelled":
      status = t("status_options.cancelled");
      statusColor = "#F35B5B";
      statusBgColor = "#FBE9E9";
      break;
    case "Late Cancel":
      status = t("status_options.late_cancel");
      statusColor = "#F35B5B";
      statusBgColor = "#FBE9E9";
      break;
    case "Created":
      status = t("status_options.created");
      statusColor = "#199CD6";
      statusBgColor = "#E4F5FC";
      break;
    default:
      status = t("status_options.completed");
  }

  if (appointment.is_closed) {
    status = t("status_options.completed");
    statusColor = "#9FB80A";
    statusBgColor = "#F7F9ED";
  }

  return (
    <Box
      color={statusColor}
      bgColor={statusBgColor}
      borderRadius={"8px"}
      padding={"4px 8px"}
      textAlign={"center"}
      width={"fit-content"}
    >
      {status}
    </Box>
  );
}

function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

const CUserReports = ({ user, accessToken, association, professional_profile }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const collaboratorData = useSelector(getData);
  const loading = useSelector(getLoading);
  const error = useSelector(getError);
  const locationsList = useSelector(selectLocations);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("participant_name");
  const [filterQuery, setFilterQuery] = useState({ status: "", location: "" });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [appointmentIsLoading, setAppointmentIsLoading] = useState(true);
  const [appointmentsHasError, setAppointmentsHasError] = useState(null);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [isErrorHandled, setIsErrorHandled] = useState(false);
  const [sessionsBlock, setSessionsBlock] = useState([])
  const [sessionsToday, setSessionsToday] = useState(0);
  const [sessionsThisWeek, setSessionsThisWeek] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [copiedAppointmentId, setCopiedAppointmentId] = useState(null);


  const [ispaymentsendtomail, setIspaymentsendtomail] = useState(false);
  // My filters

  const [filters, setFilters] = useState(() => {


    return {
      offset: 0,
      limit: 10,
      participant_name: '',
      participant_email: '',
      file_number: '',
      location: '',
      service: '',
      status: '',
      from: '',  // Current week's Sunday
      to: '',  // Current week's Saturday
      type: ''
    };
  });

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const formatDateString = (date) => {
    // Ensure the date is adjusted for the local time zone
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };

  // Function to get today's date + 1 week
  const getNextWeekDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 7); // Add 7 days to the current date
    return new Date(today.getTime() - (today.getTimezoneOffset() * 60000)); // Adjust for local timezone
  };

  const fetchAppointments = useCallback(() => {
    setAppointmentIsLoading(true);
    setIsFiltering(false);
    // someFunction();
    const query = {
      ...filterQuery,
      [searchType]: searchQuery,

    };

    const locationId = filters.location;
    const type = filters.type;
    const offset = filters.offset;
    const limit = filters.limit;
    const participant_name = searchType == "participant_name" ? searchQuery : '';
    const participant_email = searchType == "participant_email" ? searchQuery : '';
    const file_number = searchType == "file_number" ? searchQuery : '';
    const status = filters.status;
    const from = filters.from || '1970-01-01';
    const to = filters.to || getNextWeekDate().toISOString().split('T')[0];


    rdvService.serviceListCall(accessToken,
      locationId,
      type,
      offset,
      limit,
      participant_name,
      participant_email,
      file_number,
      status,
      from,
      to
    )
      .then((res) => {
        const data = Array.isArray(res.data) ? res.data : res.data.results || [];
        setAppointmentsData(data);

        const total = Array.isArray(res.data) ? res.data.length : res.data.count || 0;
        setTotalResults(total);

        // console.log("Fetched appointments:", res);

      })
      .catch((err) => {
        // console.log("err err err", err);
        setAppointmentsHasError("An error occurred. Please try again.");
        setAppointmentsData([]);
        setTotalResults(0);

        // Handle unauthorized error
        // dispatch(logout());
        // history.push('/auth/sign-in');
        // alert("error", err)
      })
      .finally(() => {
        setAppointmentIsLoading(false);
      });
  }, [accessToken, filters, searchQuery, searchType, filters.type, filters.from, filters.to, history]);

  let toastShown = false;
  const fetchStatisticsTodayAndWeek = async () => {
    try {
      const res = await rdvService.statistics(accessToken);

      if (res.error) {
        if (res.error.status === 401) {
          console.log("Unauthorized. Redirecting to sign-in.");

          if (!toastShown) {
            toast.error(`${t('session_expired_error_msg')}`, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
            toastShown = true; // Set the flag to true to prevent future toasts
          }
          setTimeout(() => {
            dispatch(logout());
            history.push('/auth/sign-in');

            // alert("log out")
          }, 2000);
        } else {
          console.log("Error fetching statistics:", res.error);
        }
      } else {
        // Process the response here, for example:
        const { sessions_today, sessions_this_week } = res.data;
        setSessionsToday(sessions_today);
        setSessionsThisWeek(sessions_this_week);
      }
    } catch (err) {
      console.log("err", err)

    } finally {
      // console.log("got statistics");
    }
  };

  useEffect(() => {
    fetchAppointments();
    fetchStatisticsTodayAndWeek();
  }, [accessToken]);

  // Debounce the fetchAppointments function
  const debouncedFetchAppointments = useCallback(debounce(fetchAppointments, 300), [fetchAppointments]);

  useEffect(() => {
    fetchAppointments();
    fetchStatisticsTodayAndWeek();
  }, [filters]);

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  // Handle date change
  const handleDateChange = (name) => (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: date
    }));
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/auth/sign-in");
    }
  }, [isAuthenticated, history]);


  const handleNextPage = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      offset: prevFilters.offset + prevFilters.limit
    }));
  }, []);

  const handlePreviousPage = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      offset: Math.max(prevFilters.offset - prevFilters.limit, 0)
    }));
  }, []);

  const handleFirstPage = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      offset: 0
    }));
  }, []);

  const handleLastPage = useCallback(() => {
    setFilters((prevFilters) => {
      const lastPageOffset = Math.floor(totalResults / prevFilters.limit) * prevFilters.limit;
      return {
        ...prevFilters,
        offset: lastPageOffset
      };
    });
  }, [totalResults]);

  const handleLimitChange = useCallback((e) => {
    const newLimit = Number(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      limit: newLimit,
      offset: 0 // Reset offset when limit changes
    }));
  }, []);

  useEffect(() => {
    debouncedFetchAppointments();
    return debouncedFetchAppointments.cancel;
  }, [debouncedFetchAppointments]);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);


  const handleSearch = () => {
    // Implement the logic for search and filter here
    // console.log("Search initiated with the following parameters:");
    // console.log("Search Type:", searchType);
    // console.log("Search Query:", searchQuery);
    // console.log("Filter Query:", filterQuery);
    // console.log("From Date:", fromDate);
    // console.log("To Date:", toDate);
  };

  const handleResetSearchAndFilter = () => {
    setSearchType('')
    setFilters({
      offset: 0,
      limit: 10,
      participant_name: '',
      participant_email: '',
      file_number: '',
      location: '',
      service: '',
      status: '',
      from: '',  // Reset to today's date or as needed
      to: '',    // Reset to today's date or as needed
      type: ''
    });
  };

  const handleCopyToClipboard = (link, appointmentId) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setCopiedAppointmentId(appointmentId);
        setTimeout(() => setCopiedAppointmentId(null), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // useEffect(() => {
  //   console.log("appointmentsData *********************************************", appointmentsData)
  // }, [appointmentsData])


  const handleSubmitNewRequest = async (formData) => {
    let info = {
      association: association?.id,
      location: formData.location,
      date: formData.date,
      time: formData.time,
      service: formData.service,
      service_concern: formData.service_themes,
      language: i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language,
      additional_info: formData.additionalDetails,
      tentative_availability_slot: formData.tentative_availability_slot,
      scheduled_by_secretary: true,
      participants: [
        {
          name: `${formData.name}`,
          status: `${formData.status}`,
          sex: `${formData.sex}`,
          city: `${formData.city}`,
          email: `${formData.email}`,
          phone: `${formData.phone}`,
        },
        {
          name: `${formData.additionalParticipant1}`,
        },
        {
          name: `${formData.additionalParticipant2}`,
        },
      ].filter((participant) => participant.name.trim() !== ""),
    };
    // console.log(info);
    try {
      let res = await rdvService.new(accessToken, info);
      toast.success(`${t("toast.successfull_new_request")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        theme: "colored",
      });
      handleClose();
      if (association.pay_first_time) {
        setIspaymentsendtomail(true);
      }
    } catch (error) {
      toast.error(`${t("toast.failed_new_request")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        theme: "colored",
      });
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    fetchAppointments();
  }, [onClose, fetchAppointments]);

  const handleClosePaymentPopup = () => {
    setIspaymentsendtomail(false);
  }

  const renderContent = () => {
    if (appointmentsHasError) {
      return <Primer text={`Error: ${error}`} />;
    }



    return (
      <>
        <SearchAndFilter
          filters={filters}
          setFilters={setFilters}
          searchType={searchType}
          setSearchType={setSearchType}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
          locationsList={professional_profile.locations}
          handleResetSearchAndFilter={handleResetSearchAndFilter}
        />

        {appointmentIsLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__appointments__status")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__file_number")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__appointments_table__location")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("consultation_mode")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("session_type")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__session_date")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("admin__main__requests_table__session_time")}
                  </Th>
                  <Th sx={tableHeaderStyles}>
                    {t("no_of_participants")}
                  </Th>
                  <Th sx={tableHeaderStyles}>

                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {isFiltering ? (
                  Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <Tr key={index}>
                        {Array(6)
                          .fill(0)
                          .map((_, cellIndex) => (
                            <Td key={cellIndex}>
                              <Skeleton height="20px" />
                            </Td>
                          ))}
                      </Tr>
                    ))
                ) : appointmentsData.length === 0 ? (
                  <Tr>
                    <Td colSpan={10}>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100px"
                      >
                        <Text
                          fontSize="lg"
                          fontWeight="medium"
                          color={"red.500"}
                        >
                          {t("admin__main__no_results_found")}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  appointmentsData.map((appointment, index) => {

                    const startTime = appointment?.calendar_block?.start_date
                      ? Utils.convertUTCToLocalTimeZone(new Date(appointment.calendar_block.start_date).toISOString().slice(11, 16)) // Extracts "HH:MM" from the ISO string in UTC
                      : 'N/A';

                    const endTime = appointment?.calendar_block?.end_date
                      ? Utils.convertUTCToLocalTimeZone(new Date(appointment.calendar_block.end_date).toISOString().slice(11, 16)) // Extracts "HH:MM" from the ISO string in UTC
                      : 'N/A';

                    return (
                      <Tr
                        key={index}
                        onClick={() =>
                          history.push(`/admin/appointment-checkin/${appointment.id}`)
                        }
                        cursor="pointer"
                        _hover={{
                          bg: "gray.200",
                        }}
                        id={appointment.id}
                      >
                        <Td>{AppointmentStatusLabel(appointment.status, t)}</Td>
                        <Td sx={tableContentStyles}>
                          {appointment?.original_rdv?.file_number || "N/A"}
                        </Td>
                        <Td
                          sx={tableContentStyles}
                          style={{
                            maxWidth: "250px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {appointment?.location?.name || "N/A"}
                        </Td>
                        <Td sx={tableContentStyles}>
                          {i18n.language === 'fr'
                            ? appointment?.consultation_mode?.name_fr
                            : appointment?.consultation_mode?.name || "N/A"}
                        </Td>
                        <Td sx={tableContentStyles}>
                          {appointment?.type || "N/A"}
                        </Td>
                        <Td sx={tableContentStyles}>
                          {appointment?.calendar_block?.start_date
                            ? Utils.intoDDMMYYYY(
                              new Date(
                                appointment.calendar_block.start_date
                              ).toISOString().split("T")[0]
                            )
                            : "N/A"}
                        </Td>
                        <Td sx={tableContentStyles}>
                          {startTime !== 'N/A' && endTime !== 'N/A'
                            ? `${startTime} - ${endTime}`
                            : 'N/A'}
                        </Td>
                        <Td sx={tableContentStyles}>
                          {appointment.original_rdv?.participants.length || "N/A"}
                        </Td>
                        <Td sx={tableContentStyles}>
                          <Tooltip label={t('copy_payment_link')} hasArrow>
                            <Text
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent row click event
                                handleCopyToClipboard(appointment.checkoutlink, appointment.id);
                              }}
                              style={{
                                width: '65px',
                                cursor: "pointer",
                                color: copiedAppointmentId === appointment.id ? "green" : "#FF6666",
                                fontWeight: "bold",
                              }}
                            >
                              {copiedAppointmentId === appointment.id ? t('copied') : t('copy')}
                            </Text>
                          </Tooltip>
                        </Td>
                      </Tr>
                    )
                  })
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Text mr={4}>
            {t("Rows_per_page")}
            <Select
              value={filters.limit}
              onChange={handleLimitChange}
              display="inline-block"
              width="auto"
              ml={2}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Select>
          </Text>
          <Text mr={4}>
            {`${filters.offset + 1}-${Math.min(filters.offset + filters.limit, totalResults)} of ${totalResults}`}
          </Text>
          <Flex>
            <IconButton
              onClick={handleFirstPage}
              disabled={filters.offset === 0}
              icon={<Image src={ToFirstPage} alt="First Page" />}
              aria-label="First Page"
              bg={'none'}
              mr={2}
            />
            <IconButton
              onClick={handlePreviousPage}
              disabled={filters.offset === 0}
              icon={<ChevronLeftIcon boxSize={6} />}
              aria-label="Previous Page"
              bg={'none'}
              mr={2}
            />
            <IconButton
              onClick={handleNextPage}
              disabled={filters.offset + filters.limit >= totalResults}
              icon={<ChevronRightIcon boxSize={6} />}
              aria-label="Next Page"
              bg={'none'}
              mr={2}
            />
            <IconButton
              onClick={handleLastPage}
              disabled={filters.offset + filters.limit >= totalResults}
              icon={<Image src={ToLastPage} alt="Last Page" />}
              aria-label="Last Page"
              border={'none'}
              bg={'none'}
            />
          </Flex>
        </Flex>
      </>
    );
  };


  return (
    <>
      <BrandHeader />
      <ToastContainer />
      <Flex direction="row" width="100%" justify="space-between" paddingX={'12px'} pb={'12px'}>
        <Text fontSize={{ base: '1xl', md: '2xl' }}>
          {t("common_welcome")} {`${professional_profile.first_name} ${professional_profile.last_name}`}
        </Text>
      </Flex>

      <Flex direction="row" width="100%" justify="space-around" paddingX={'12px'} gap={'10px'}>
        <Box width="50%" padding={2} display="flex" alignItems="center" border={'1px'} borderRadius={'10px'} borderColor={'gray.300'}>
          <Circle size="40px" bg="#FF8080" color="white" mr={4}>
            <Text fontWeight="bold">{sessionsToday}</Text>
          </Circle>
          <Box>
            <Text fontWeight="bold">{t("admin__appointments__sessions")}</Text>
            <Text>{t("sessions_today")}</Text>
          </Box>
        </Box>
        <Box width="50%" padding={2} display="flex" alignItems="center" border={'1px'} borderRadius={'10px'} borderColor={'gray.300'}>
          <Circle size="40px" bg="#FF8080" color="white" mr={4}>
            <Text fontWeight="bold">{sessionsThisWeek}</Text>
          </Circle>
          <Box>
            <Text fontWeight="bold">{t("admin__appointments__sessions")}</Text>
            <Text>{t("sessions_this_week")}</Text>
          </Box>
        </Box>
      </Flex>
      <Flex
        direction={"column"}
        margin={3}
        border="1px"
        borderColor="gray.300"
        borderRadius={"8px"}
      >
        <Flex justifyContent={"space-between"}>
          <Box
            fontSize={"24px"}
            fontWeight={"500"}
            marginLeft={3}
            marginY={"12px"}
          >
            {t("admin__appointments__sessions")}
          </Box>

          <Button
            background={"none"}
            fontSize={"18px"}
            fontWeight={"500"}
            marginY={"12px"}
            color={"rgb(255, 102, 102)"}
            onClick={onOpen}
          >
            {t(`admin_add_new_request`)}
          </Button>
        </Flex>
        {renderContent()}
      </Flex>

      {isOpen ?
        <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t(`admin_add_new_request`)}</ModalHeader>
            <ModalBody>
              <NewRequestForm
                onSubmit={handleSubmitNewRequest}
                onClose={handleClose}
                locationsList={professional_profile.locations}
                servicesList={professional_profile}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
        : ''}

      {ispaymentsendtomail ?
        <Modal isOpen={ispaymentsendtomail} onClose={handleClosePaymentPopup} size="lg" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader display="flex" alignItems="center">
              <FaCheckCircle color="green" style={{ marginRight: "8px" }} />
              {t("payment_link_sent_header")}
            </ModalHeader>
            <ModalBody>
              <Text fontSize="lg" paddingY={5}>{t("payment_link_sent")}</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
        : ''}
    </>
  );
};



const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  association: selectAssociation(state),
  professional_profile: selectProfessionalProfile(state)
});

const ConnectedUserReports = connect(mapStateToProps)(CUserReports);

export default function UserReports() {
  return (
    <ConnectedUserReports />
  );
}