import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Spinner,
  Skeleton,
  Select,
  IconButton,
  Image,
} from "@chakra-ui/react";
import axios from 'axios';
import Card from "components/card/Card";
import BrandHeader from "components/menu/BrandHeader";
import { connect } from "react-redux";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
} from "selectors/authSelector";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ToFirstPage from "../../../assets/img/dashboards/first.svg";
import ToLastPage from "../../../assets/img/dashboards/last.svg";
import { Location } from "../marketplace/variables/TableLocations.model"

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

const LocationList = ({ user, accessToken, association }) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [locationsListIsLoading, setLocationsListIsLoading] =
    useState(true);
  const [
    locationListFetchHasError,
    setLocationListFetchHasError,
  ] = useState(null);
  const [locationListData, setLocationListData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);

  const fetchLocations = useCallback(async () => {
    setLocationsListIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/collaboration-api/${association.slug}/location`, {
        params: { limit, offset }
      });
      console.log(response);
      setLocationListData(response.data.results);
      setTotalResults(response.data.count);
      setLocationsListIsLoading(false);
    } catch (err) {
      setLocationListFetchHasError(err);
      setLocationsListIsLoading(false);
    }
  }, [accessToken, limit, offset]);

  const handleNextPage = useCallback(() => {
    setOffset((prevOffset) => prevOffset + limit);
  }, [limit]);

  const handlePreviousPage = useCallback(() => {
    setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
  }, [limit]);

  const handleFirstPage = useCallback(() => {
    setOffset(0);
  }, []);

  const handleLastPage = useCallback(() => {
    const lastPageOffset = Math.floor(totalResults / limit) * limit;
    setOffset(lastPageOffset);
  }, [totalResults, limit]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const renderContent = () => {
    if (locationListFetchHasError) {
      return <Primer text={`Error: ${locationListFetchHasError.message}`} />;
    }

    return (
      <>
        {locationsListIsLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__address")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__city")}</Th>
                  <Th sx={tableHeaderStyles}>{t("admin__location_common__department")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {locationsListIsLoading ? (
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <Tr key={index}>
                        {Array(6)
                          .fill(0)
                          .map((_, cellIndex) => (
                            <Td key={cellIndex}>
                              <Skeleton height="20px" />
                            </Td>
                          ))}
                      </Tr>
                    ))
                ) : locationListData.length === 0 ? (
                  <Tr>
                    <Td colSpan={6}>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100px"
                      >
                        <Text
                          fontSize="lg"
                          fontWeight="medium"
                          color={"red.500"}
                        >
                          {t("admin__main__no_results_found")}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  locationListData.map((location, index) => (
                    <Tr key={index}>
                      <Td>{location.name}</Td>
                      <Td>{location.address}</Td>
                      <Td>{location.city}</Td>
                      <Td>{location.department}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Text mr={4}>
            Rows per page:
            <Select
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              display="inline-block"
              width="auto"
              ml={2}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Select>
          </Text>
          <Text mr={4}>
            {`${Math.min(offset + 1, totalResults)}-${Math.min(
              offset + limit,
              totalResults
            )} of ${totalResults}`}
          </Text>
          <Flex>
            <IconButton
              onClick={handleFirstPage}
              disabled={offset === 0}
              icon={<Image src={ToFirstPage} alt="First Page" />}
              aria-label="First Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handlePreviousPage}
              disabled={offset === 0}
              icon={<ChevronLeftIcon boxSize={6} />}
              aria-label="Previous Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleNextPage}
              disabled={offset + limit >= totalResults}
              icon={<ChevronRightIcon boxSize={6} />}
              aria-label="Next Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleLastPage}
              disabled={offset + limit >= totalResults}
              icon={<Image src={ToLastPage} alt="Last Page" />}
              aria-label="Last Page"
              border={"none"}
              bg={"none"}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <BrandHeader />
      <ToastContainer />
      <Flex
        direction={"column"}
        margin={3}
        border="1px"
        borderColor="gray.300"
        borderRadius={"8px"}
      >
        <Flex justifyContent={"space-between"}>
          <Box
            fontSize={"24px"}
            fontWeight={"500"}
            marginLeft={3}
            marginY={"12px"}
          >
            {t("admin__header__locations")}
          </Box>

          <Button
            background={"none"}
            fontSize={"18px"}
            fontWeight={"500"}
            marginY={"12px"}
            color={"rgb(255, 102, 102)"}
            disabled={true}
          >
            {/*t(`admin_invite_new_service_provider`)*/}
          </Button>
        </Flex>
        {renderContent()}
      </Flex>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  association: selectAssociation(state),
});

const ConnectedLocationList = connect(mapStateToProps)(LocationList);

export default function LocationsPage() {
  return <ConnectedLocationList />;
}
