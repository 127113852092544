import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  Box,
  Button,
  Link,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  FormControl,
  FormLabel,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody
} from "@chakra-ui/react";

import RequestDetails from "./RequestDetails";
import Sessions from "./Session";
import RdvCommentsTab from "./RdvCommentsTab";
import CreateFirstSessionDialog from "./CreateFirstSessionDialog";
import locationService from "services/locationService";
// Language Management
import { useTranslation } from "react-i18next";

// Dialogs
import CompleteRequest from "./CompleteRequest.dialog";
import { FaCheckCircle } from "react-icons/fa";

const activeTabStyle = {
  color: "#FF6666",
  fontSize: "18px",
  fontWeight: "600",
};

const inactiveTabStyle = {
  color: "#565656",
  fontSize: "18px",
  fontWeight: "600",
};

const CAppointmentDetails = ({
  data,
  collaboratorsData,
  accessToken,
  onStateChange,
  professional_profile,
  association
}) => {
  // console.log(data);
  // Init i18n
  const { t, i18n } = useTranslation();

  // State Management
  const [active, setActive] = useState("details");

  // Complete Request State
  const [sessionClosureDialogOpen, setSessionClosureDialogOpen] = useState(false);

  const [isFirstSessionDialogOpen, setIsFirstSessionDialogOpen] = useState(false);

  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(data.location);

  const [ispaymentsendtomail, setIspaymentsendtomail] = useState(false);


  useEffect(() => {
    // console.log("association.slug", association.slug)
    locationService.getAll(association.slug).then((response) => {
      setAvailableLocations(response.data);
    });
  }, [association.slug]);

  const lastCreatedSession = data.original_rdv.rdv_sessions?.reduce((latest, session) => {
    return new Date(session.calendar_block.created_at) > new Date(latest.calendar_block.created_at) ? session : latest;
  });

  const [isCompletReqState, setIsCompletReqState] = useState(false);

  useEffect(() => {
    const checkStatus = () => {
      const sessions = data.original_rdv.rdv_sessions;

      // Filter sessions with status 'Scheduled' or 'Postponed'
      const filteredSessions = sessions.filter(session =>
        session.status === 'Scheduled' || session.status === 'Postponed' || session.status === 'Created'
      );

      // Set the count of such sessions
      if (filteredSessions.length > 0) {
        setIsCompletReqState(true);
      } else {
        setIsCompletReqState(false)
      }
    };

    checkStatus();
  }, [data.original_rdv?.rdv_sessions]);

  const handleClose = () => {
    setSessionClosureDialogOpen(false);
    onStateChange(); // Call refresh here
  };

  const downloadFile = (attestation) => {
    // const link = document.createElement('a');
    // link.href = attestation.file;
    // link.setAttribute('download', `${attestation.file_name}`); // Customize the file name if needed
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
    window.open(attestation.file, '_blank');
  };

  const handleDownloadFile = () => {
    console.log("onclick data", data?.original_rdv?.rdv_completion?.attestations)
    // Get the current language from i18n
    const currentLanguage = i18n.language.toUpperCase(); // Convert the i18n language to upper case

    // Check if attestations array exists
    const attestations = data?.original_rdv?.rdv_completion?.attestations;

    if (attestations?.length > 0) {
      // Find the attestation that matches the current language
      const matchingAttestation = attestations.find(attestation => attestation?.language === currentLanguage);

      if (matchingAttestation) {
        // Trigger download for the attestation that matches the current language
        downloadFile(matchingAttestation);
      } else {
        console.log("No attestation found for the current language");
      }
    } else {
      console.log("Attestations not generated");
    }
  }

  const handleClosePaymentPopup = () => {
    setIspaymentsendtomail(false);
  }

  const handleOpenPaymentPopup = () => {
    setIspaymentsendtomail(true)
  }
  // console.log("data", data)

  return (
    <Box>
      <Flex direction={"column"}>
        <Flex justify={"space-between"} alignItems={"center"} marginX={4} width={'98%'}>
          <Box color={"#252525"} fontSize={"22px"} fontWeight={"500"}>
            {t("admin__appointments__req_file")} &lt;
            {data?.original_rdv?.file_number}&gt;
          </Box>
          {data.original_rdv?.is_closed ?
            <Flex>
              {/*  Dont show the button if the mediator is null */}
              {/* {data.mediator && !data.is_closed && ( */}
              <Button
                fontSize={"16px"}
                color={"white"}
                backgroundColor={"#EE6464"}
                padding={2}
                mb={3}
                onClick={handleDownloadFile}
                sx={{
                  transition: 'all 0.2s ease-in-out',
                  _hover: { bg: '#FF4C4C' },
                  _click: { bg: 'inherit' }
                }}
              // isDisabled={isCompletReqState}
              >
                {t("admin_download_final_attestation")}
              </Button>
              {/* )} */}
            </Flex> :
            <Tooltip
              label="Only available when all sessions are occurred or cancelled"
              isDisabled={!isCompletReqState} // Tooltip only shows when button is disabled
            >
              <Flex>
                {/*  Dont show the button if the mediator is null */}
                {/* {data.mediator && !data.is_closed && ( */}
                {association?.slug !== 'kiido' ?
                  <Button
                    fontSize={"16px"}
                    color={"white"}
                    backgroundColor={"#EE6464"}
                    padding={2}
                    mb={3}
                    onClick={() => setSessionClosureDialogOpen(true)}
                    sx={{
                      transition: 'all 0.2s ease-in-out',
                      _hover: { bg: '#FF4C4C' },
                      _click: { bg: 'inherit' }
                    }}
                    isDisabled={isCompletReqState}
                  >
                    {t("admin__appointments__complete_req")}
                  </Button>
                  : ''}
                {/* )} */}
              </Flex>
            </Tooltip>
          }
        </Flex>
        <Flex
          height={1}
          width={"100%"}
          borderBottom={"solid 1px #E7E7E7"}
        ></Flex>
        <Tabs
          variant="enclosed" isLazy
          onChange={(index) => setActive(index === 0 ? "details" : index === 1 ? "comments" : index === 2 ? "sessions" : "rdv_docs")}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <TabList mb={3}>
              <Tab
                ml={5}
                sx={active === "details" ? activeTabStyle : inactiveTabStyle}
                _selected={{
                  color: '#FF6666',
                  borderBottom: '2px solid #FF6666',
                }}
                _focus={{ boxShadow: 'none' }}
              >
                {t("admin__appointments__sessions")} ({data.original_rdv?.rdv_sessions?.length})

              </Tab>

              <Tab sx={active === "comments" ? activeTabStyle : inactiveTabStyle}
                _selected={{
                  color: '#FF6666',
                  borderBottom: '2px solid #FF6666',
                }}
                _focus={{ boxShadow: 'none' }}
              >
                {t("admin__appointments__comments")} ({data?.original_rdv?.thread?.length})
              </Tab>

              <Tab sx={active === "sessions" ? activeTabStyle : inactiveTabStyle}
                _selected={{
                  color: '#FF6666',
                  borderBottom: '2px solid #FF6666',
                }}
                _focus={{ boxShadow: 'none' }}
              >
                {t("admin__appointments__req_details")}

              </Tab>

            </TabList>
            {data.original_rdv.is_closed ? '' :
              <Button
                background={"none"}
                fontSize={"18px"}
                fontWeight={"500"}
                marginY={"0px"}
                color={"rgb(255, 102, 102)"}
                isDisabled={data.original_rdv?.is_closed}
                onClick={() => setIsFirstSessionDialogOpen(true)}
              >
                {t("add_new_session")}
              </Button>}
          </Flex>
          <TabPanels>
            <TabPanel>
              <Sessions
                collaboratorsData={collaboratorsData}
                appointmentData={data.original_rdv}
                accessToken={accessToken}
                onStateChange={onStateChange}
                professional_profile={professional_profile}
                association={association}
              />
            </TabPanel>
            <TabPanel>
              <RdvCommentsTab
                data={data}
                accessToken={accessToken}
                onStateChange={onStateChange}
              />
            </TabPanel>

            <TabPanel>
              <RequestDetails
                data={data}
                collaboratorsData={collaboratorsData}
                accessToken={accessToken}
                refresh={onStateChange}
                association={association}
              />
            </TabPanel>

            {/*
            <TabPanel>
              <RdvGeneralDocs
                documents={data.documents}
                accessToken={accessToken}
                appointmentData={data}
                onStateChange={onStateChange}
              />
            </TabPanel>
            */}
          </TabPanels>
        </Tabs>
      </Flex>

      {isFirstSessionDialogOpen && (
        <CreateFirstSessionDialog
          isOpen={isFirstSessionDialogOpen}
          onClose={() => setIsFirstSessionDialogOpen(false)}
          accessToken={accessToken}
          originalRdvId={data.original_rdv.id}
          allMediators={collaboratorsData}
          liftStateUp={() => onStateChange()}
          availableLocations={availableLocations}
          preferredLocation={lastCreatedSession.location}
          proposedDate={data.original_rdv.rdv_sessions.length === 0 ? data.original_rdv.date : new Date()}
          proposedTime={data.original_rdv.rdv_sessions.length === 0 ? data.original_rdv.time : ""}
          serviceId={data.original_rdv.service.id}
          isFirstSession={data.original_rdv.rdv_sessions.length === 0}
          principalParticipant={data.original_rdv.participants[0].name}
          association={association}
          alldata={data}
          handleClosePaymentPopup={handleClosePaymentPopup}
          handleOpenPaymentPopup={handleOpenPaymentPopup}
        />
      )}

      {sessionClosureDialogOpen ? <CompleteRequest
        isOpen={sessionClosureDialogOpen}
        onClose={handleClose}
        accessToken={accessToken}
        originalRdvId={data.original_rdv.id}
      /> : ''}

      {ispaymentsendtomail ?
        <Modal isOpen={ispaymentsendtomail} onClose={handleClosePaymentPopup} size="lg" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader display="flex" alignItems="center">
              <FaCheckCircle color="green" style={{ marginRight: "8px" }} />
              {t("payment_link_sent_header")}
            </ModalHeader>
            <ModalBody>
              <Text fontSize="lg" paddingY={5}>{t("payment_link_sent")}</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
        : ''}
    </Box>
    // <h1>Test</h1>
  );
};

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function AppointmentDetails({
  data,
  collaboratorsData,
  accessToken,
  onStateChange,
  professional_profile,
  association
}) {
  return (
    <CAppointmentDetails
      data={data}
      collaboratorsData={collaboratorsData}
      accessToken={accessToken}
      onStateChange={onStateChange}
      professional_profile={professional_profile}
      association={association}
    />
  );
}
