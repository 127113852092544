// Chakra imports
import { Box, Flex, Icon, Text, Image } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import Image1 from "../../assets/img/auth/1_backstage.png";
import Image2 from "../../assets/img/auth/2.png";
import Image3 from "../../assets/img/auth/3.png";
import Image4 from "../../assets/img/auth/4.png";
import Image5 from "../../assets/img/auth/5.png";
import Image6 from "../../assets/img/auth/6.png";
import Image7 from "../../assets/img/auth/7.png";
import { FaChevronLeft } from "react-icons/fa";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w="100%"
        maxW={{ md: "66%", lg: "1313px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent="start"
        direction="column"
      >

        {children}
        <Box
          display={{ base: "none", md: "block" }}
          // bgColor={'#fe6766'}
          h="100%"
          minH="100vh"
          w={{ lg: "50vw", "2xl": "44vw" }}
          position="absolute"
          right="0px"
        >
          <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            marginX={"auto"}
            w="100%"
            h="100%"
            bgSize="cover"
            bgRepeat={"no-repeat"}
            bgPosition="center"
            position="absolute"
          // borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
          >
            <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
              <Image src={Image1} style={{ scale: '0.8' }}></Image>
            </Box>
            <Box position="absolute" top={"4%"} left={"25%"}>
              <Image src={Image2} style={{ scale: '0.8' }}></Image>
            </Box>
            <Box position="absolute" top={"15%"} right={"13%"}>
              <Image src={Image3} style={{ scale: '0.8' }}></Image>
            </Box>
            <Box position="absolute" top={"30%"} left={"5%"}>
              <Image src={Image4} style={{ scale: '0.8' }}></Image>
            </Box>
            <Box position="absolute" top={"55%"} right={"5%"}>
              <Image src={Image5} style={{ scale: '0.8' }}></Image>
            </Box>
            <Box position="absolute" top={"70%"} left={"15%"}>
              <Image src={Image6} style={{ scale: '0.8' }}></Image>
            </Box>
            <Box position="absolute" top={"80%"} right={"23%"}>
              <Image src={Image7} style={{ scale: '0.8' }}></Image>
            </Box>

          </Flex>
        </Box>
        <Footer />
      </Flex>
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
