import React from 'react';
import Navbar from './components/Navbar'; // Update the import path according to your project structure
import { Switch, Route } from 'react-router-dom';
import Profile from './profile';
import Availability from './availability';
import Unavailability from './unavailability'
import Configurations from './configurations'; // Make sure to create this component
import BrandHeader from 'components/menu/BrandHeader';
import { connect } from 'react-redux';
import { selectAccessToken, selectProfessionalProfile } from 'selectors/authSelector';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Index = ({ accessToken, professional_profile }) => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <BrandHeader />
      <Flex justifyContent="space-between" marginX={4} mb={1}>
        <Text fontSize={{ base: '2xl', md: '3xl' }}>
          {`${professional_profile.first_name} ${professional_profile.last_name}`}
        </Text>
      </Flex>
      <Navbar />
      <Switch>
        <Route path="/admin/settings/profile" component={Profile} />
        <Route path="/admin/settings/availability/" component={Availability} />
        {/* <Route path="/admin/settings/unavailability" component={Unavailability} /> */}
        {/* <Route path="/admin/settings/configurations" component={Configurations} /> */}
      </Switch>

    </div>
  );
};
const mapStateToProps = (state) => ({
  accessToken: selectAccessToken(state),
  professional_profile: selectProfessionalProfile(state)
});

export default connect(mapStateToProps)(Index);
