import React, {
  useEffect,
  useState,
  Suspense,
  useRef,
  useCallback,
} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Spinner,
  Skeleton,
  Select,
  IconButton,
  Image,
} from "@chakra-ui/react";
import collaboratorService from "services/collaboratorService";
import Card from "components/card/Card";
import SearchAndFilter from "./components/SearchAndFilter";
import NewRequestForm from "./components/NewRequestForm";
import { useHistory } from "react-router-dom";
import BrandHeader from "components/menu/BrandHeader";
import { connect } from "react-redux";
import {
  selectUser,
  selectAccessToken,
  selectAssociation,
  selectLocations,
} from "selectors/authSelector";
import {
  getData,
  getError,
  getLoading,
} from "../../../selectors/collaboratorSelector";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../actions/collaboratorActions.js";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import i18n from "i18n";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ToFirstPage from "../../../assets/img/dashboards/first.svg";
import ToLastPage from "../../../assets/img/dashboards/last.svg";

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "16px",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "16px",
  fontWeight: "400",
  color: "#565656",
};

function Primer({ text }) {
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text fontSize="lg" fontFamily="heading" fontWeight="500">
          {text}
        </Text>
      </Flex>
    </Card>
  );
}

const CUserReports = ({ user, accessToken, association }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const collaboratorData = useSelector(getData);
  const loading = useSelector(getLoading);
  const error = useSelector(getError);
  const locationsList = useSelector(selectLocations);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("participant_name");
  const [filterQuery, setFilterQuery] = useState({ status: "", location: "" });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [serviceProviderListIsLoading, setServiceProviderListIsLoading] =
    useState(true);
  const [
    serviceProviderListFetchHasError,
    setServiceProviderListFetchHasError,
  ] = useState(null);
  const [serviceProviderListData, setServiceProviderListData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);

  const assoc = useSelector(selectAssociation);

  const fetchServiceProviders = useCallback(() => {
    setServiceProviderListIsLoading(true);
    setIsFiltering(false);
    collaboratorService
      .getAll(limit, offset)
      .then((res) => {
        // console.log("Setting Service Providers: ", res.data);
        setServiceProviderListData(res.data.results);
        setTotalResults(res.data.count);
        setServiceProviderListIsLoading(false);
      })
      .catch((err) => {
        setServiceProviderListFetchHasError(err);
        setServiceProviderListIsLoading(false);
      });
  }, [accessToken, limit, offset, history]);

  const handleNextPage = useCallback(() => {
    setOffset((prevOffset) => prevOffset + limit);
  }, [limit]);

  const handlePreviousPage = useCallback(() => {
    setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
  }, [limit]);

  const handleFirstPage = useCallback(() => {
    setOffset(0);
  }, []);

  const handleLastPage = useCallback(() => {
    const lastPageOffset = Math.floor(totalResults / limit) * limit;
    setOffset(lastPageOffset);
  }, [totalResults, limit]);

  useEffect(() => {
    fetchServiceProviders();
  }, [fetchServiceProviders]);

  const renderContent = () => {
    if (serviceProviderListFetchHasError) {
      return <Primer text={`Error: ${error}`} />;
    }

    return (
      <>
        {serviceProviderListIsLoading ? (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <Spinner size="xl" color="blue.500" />
          </Flex>
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th sx={tableHeaderStyles}>{t("common__first_name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("common__last_name")}</Th>
                  <Th sx={tableHeaderStyles}>{t("common__email")}</Th>
                  <Th sx={tableHeaderStyles}>{t("common__about")}</Th>
                  <Th sx={tableHeaderStyles}>{t("common__designation")}</Th>
                  <Th sx={tableHeaderStyles}>{t("common__services")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {isFiltering ? (
                  // Skeleton loading state
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <Tr key={index}>
                        {Array(10)
                          .fill(0)
                          .map((_, cellIndex) => (
                            <Td key={cellIndex}>
                              <Skeleton height="20px" />
                            </Td>
                          ))}
                      </Tr>
                    ))
                ) : serviceProviderListData?.length === 0 ? (
                  // No results found
                  <Tr>
                    <Td colSpan={10}>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100px"
                      >
                        <Text
                          fontSize="lg"
                          fontWeight="medium"
                          color={"red.500"}
                        >
                          {t("admin__main__no_results_found")}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  // Actual data
                  serviceProviderListData.map((serviceProvider, index) => (
                    <Tr key={index}>
                      <Td>{serviceProvider.first_name}</Td>
                      <Td>{serviceProvider.last_name}</Td>
                      <Td>{serviceProvider.email}</Td>
                      <Td>{serviceProvider.about}</Td>
                      <Td>{serviceProvider.designation}</Td>
                      <Td>
                        {serviceProvider.consultation_specialities
                          .map((serviceObj) => serviceObj.name)
                          .join(", ")}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Flex justifyContent="flex-end" alignItems="center" mt={4}>
          <Text mr={4}>
            Rows per page:
            <Select
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              display="inline-block"
              width="auto"
              ml={2}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Select>
          </Text>
          <Text mr={4}>
            {`${Math.min(offset + 1, totalResults)}-${Math.min(
              offset + limit,
              totalResults
            )} of ${totalResults}`}
          </Text>
          <Flex>
            <IconButton
              onClick={handleFirstPage}
              disabled={offset === 0}
              icon={<Image src={ToFirstPage} alt="First Page" />}
              aria-label="First Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handlePreviousPage}
              disabled={offset === 0}
              icon={<ChevronLeftIcon boxSize={6} />}
              aria-label="Previous Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleNextPage}
              disabled={offset + limit >= totalResults}
              icon={<ChevronRightIcon boxSize={6} />}
              aria-label="Next Page"
              bg={"none"}
              mr={2}
            />
            <IconButton
              onClick={handleLastPage}
              disabled={offset + limit >= totalResults}
              icon={<Image src={ToLastPage} alt="Last Page" />}
              aria-label="Last Page"
              border={"none"}
              bg={"none"}
            />
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <BrandHeader />
      <ToastContainer />
      <Flex
        direction={"column"}
        margin={3}
        border="1px"
        borderColor="gray.300"
        borderRadius={"8px"}
      >
        <Flex justifyContent={"space-between"}>
          <Box
            fontSize={"24px"}
            fontWeight={"500"}
            marginLeft={3}
            marginY={"12px"}
          >
            {t("admin__header__service_providers")}
          </Box>

          <Button
            background={"none"}
            fontSize={"18px"}
            fontWeight={"500"}
            marginY={"12px"}
            color={"rgb(255, 102, 102)"}
            disabled={true}
          >
            {t(`admin_invite_new_service_provider`)}
          </Button>
        </Flex>
        {renderContent()}
      </Flex>

      {/* <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t(`admin_add_new_request`)}</ModalHeader>
          <ModalBody>
            <NewRequestForm
              onSubmit={handleSubmitNewRequest}
              onClose={handleClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

function AppointmentStatusLabel(appointment, t) {
  let status = t("admin_status.in_progress");
  let statusColor = "#199CD6";
  let statusBgColor = "#E4F5FC";

  if (appointment.mediator === null) {
    status = t("admin_status_new");
    statusColor = "#FF6666";
    statusBgColor = "#FFEEEE";
  }

  if (appointment.is_closed) {
    status = t("admin_status.completed");
    statusColor = "#9FB80A";
    statusBgColor = "#F7F9ED";
  }

  return (
    <Box
      color={statusColor}
      bgColor={statusBgColor}
      borderRadius={"8px"}
      padding={"4px 8px"}
      textAlign={"center"}
      width={"fit-content"}
    >
      {status}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  association: selectAssociation(state),
});

const ConnectedUserReports = connect(mapStateToProps)(CUserReports);

export default function ServiceProvidersList() {
  return <ConnectedUserReports />;
}
