import { Box, Button, Flex, Text, FormControl, FormLabel, Input, Switch } from '@chakra-ui/react';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import {
    selectUser,
    selectAccessToken,
    selectAssociation,
    selectProfessionalProfile
} from "selectors/authSelector";
import { useTranslation } from 'react-i18next';

const Index = ({ professional_profile }) => {
    const { t } = useTranslation();

    // State to manage form visibility and data
    const [isFormVisible, setFormVisible] = useState(false);
    const [formData, setFormData] = useState({
        mediatorName: professional_profile.first_name + ' ' + professional_profile.last_name || '',
        allDay: false,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        reason: '',
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' || type === 'switch' ? checked : value,
        });
    };

    // Handle form submission
    const handleSubmit = () => {
        // Submit form data (add your API call logic here)
        console.log(formData);
        // Reset form and hide it after submission
        setFormData({
            mediatorName: professional_profile.first_name || '',
            allDay: false,
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            reason: '',
        });
        setFormVisible(false);
    };

    return (
        <Box p={6} pt={3}>
            <Flex alignItems="center" justifyContent="space-between">
                <Box>
                    <Text fontSize="lg" fontWeight="bold" mb="1">
                        {t('unavailability.unavailability_heading')}
                    </Text>
                    <Text color="gray.600">
                        {t('unavailability.unavailability_descriptino')}
                    </Text>
                </Box>
                <Button colorScheme="red" variant="solid" onClick={() => setFormVisible(true)}>
                    {t('unavailability.add_unavailability')}
                </Button>
            </Flex>

            {/* Form for adding unavailability */}
            {isFormVisible && (
                <Box mt={4} p={4} borderWidth="1px" borderRadius="md">
                    <FormControl mb={4}>
                        <FormLabel>{t('unavailability.mediator_name')}</FormLabel>
                        <Input
                            name="mediatorName"
                            value={formData.mediatorName}
                            onChange={handleChange}
                            isReadOnly
                        />
                    </FormControl>

                    <FormControl mb={4} display="flex" alignItems="center">
                        <FormLabel htmlFor="allDay" mb="0">
                            {t('unavailability.all_day_unavailability')}
                        </FormLabel>
                        <Switch
                            id="allDay"
                            name="allDay"
                            isChecked={formData.allDay}
                            onChange={handleChange}
                            css={{
                                '--switch-color': '#FF6666',
                            }}
                            sx={{
                                '& .chakra-switch__track': {
                                    backgroundColor: formData.allDay ? '#FF6666' : '',
                                },
                            }}
                        />
                    </FormControl>

                    <Flex mb={4}>
                        <FormControl mr={4}>
                            <FormLabel>{t('unavailability.start_date')}</FormLabel>
                            <Input
                                type="date"
                                name="startDate"
                                value={formData.startDate}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('unavailability.start_time')}</FormLabel>
                            <Input
                                type="time"
                                name="startTime"
                                value={formData.startTime}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Flex>

                    <Flex mb={4}>
                        <FormControl mr={4}>
                            <FormLabel>{t('unavailability.end_date')}</FormLabel>
                            <Input
                                type="date"
                                name="endDate"
                                value={formData.endDate}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('unavailability.end_time')}</FormLabel>
                            <Input
                                type="time"
                                name="endTime"
                                value={formData.endTime}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Flex>

                    <FormControl mb={4}>
                        <FormLabel>{t('unavailability.reason')}</FormLabel>
                        <Input
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            placeholder={t('unavailability.enter_reason')}
                        />
                    </FormControl>

                    <Flex justifyContent="flex-end">
                        <Button colorScheme="red" onClick={handleSubmit}>
                            {t('unavailability.submit')}
                        </Button>
                        <Button variant="outline" ml={3} onClick={() => setFormVisible(false)}>
                            {t('unavailability.cancel')}
                        </Button>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: selectUser(state),
    accessToken: selectAccessToken(state),
    professional_profile: selectProfessionalProfile(state)
})

export default connect(mapStateToProps)(Index);
