import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import ParticipantModal from "./ParticipantModal";
import { toast } from "react-toastify";
import rdvService from "services/rdvService";
import GenericAlertDialog from "./GenericAlertDialog";
import { useTranslation } from "react-i18next";

const tableHeaderStyles = {
  textAlign: "start",
  fontSize: "14px",
  fontWeight: "600",
  color: "#252525",
};

const tableContentStyles = {
  fontSize: "14px",
  fontWeight: "400",
  color: "#565656",
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^(?:\+?[1-9]\d{1,14}|\d{10})$/;
const postalCodeRegex = /^\d{5}$/; // Basic 5-digit postal code

const validateEmail = (email) => emailRegex.test(email);
const validatePhone = (phone) => phoneRegex.test(phone);
const validatePostalCode = (postalCode) => postalCodeRegex.test(postalCode);

const ParticipantSection = ({ data, accessToken, refresh, association }) => {
  const { t } = useTranslation();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const initialParticipantRef = useRef(null);
  const finalParticipantRef = useRef(null);
  const [alertConfirmCallback, setAlertConfirmCallback] = useState(() => () => { });

  const handleAddParticipant = useCallback(async (participantForm) => {
    // console.log("participantForm and all data ", {
    //   "pk": data.id,
    //   "participant": participantForm,
    //   "accessToken": accessToken,
    // })
    try {
      await rdvService.addParticipant({
        pk: data.id,
        participant: participantForm,
        accessToken: accessToken,
      });
      toast.success(`${t("messages.participantAdded")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      refresh();
    } catch (error) {
      console.error("Failed to add participant:", error);
      toast.error(`${t("messages.failedToAddParticipant")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [data?.id, accessToken, refresh, t]);

  const handleEditParticipant = useCallback(async (participantForm) => {
    // console.log("edit participant data", {
    //   "rdvId": data.id,
    //   "participantId": currentParticipant.id,
    //   "obj": participantForm,
    //   "accessToken": accessToken,
    // })
    try {
      await rdvService.updateParticipantInfo({
        rdvId: data.id,
        participantId: currentParticipant.id,
        obj: participantForm,
        accessToken,
      });
      toast.success(`${t("messages.participantUpdated")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refresh();
    } catch (error) {
      console.error("Failed to update participant:", error);
      toast.error(`${t("messages.failedToUpdateParticipant")}`, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    }
  }, [data?.id, currentParticipant, accessToken, refresh, t]);

  const validateParticipantForm = (form) => {
    const errors = {};

    if (!form.name.trim()) {
      errors.name = t('errors.nameRequired');
    } else if (form.name.length < 2 || form.name.length > 50) {
      errors.name = t('errors.nameLengthInvalid');
    }

    if (!form.status) {
      errors.status = t('errors.statusRequired');
    }

    if (!form.address.trim()) {
      errors.address = t('errors.addressRequired');
    } else if (form.address.length < 5 || form.address.length > 100) {
      errors.address = t('errors.addressLengthInvalid');
    }

    if (!form.cp.trim()) {
      errors.cp = t('errors.postalCodeRequired');
    } else if (!validatePostalCode(form.cp)) {
      errors.cp = t('errors.invalidPostalCode');
    }

    if (!form.phone.trim()) {
      errors.phone = t('errors.phoneRequired');
    } else if (!validatePhone(form.phone)) {
      errors.phone = t('errors.invalidPhoneNumber');
    }

    if (!form.email.trim()) {
      errors.email = t('errors.emailRequired');
    } else if (!validateEmail(form.email)) {
      errors.email = t('errors.invalidEmailFormat');
    }

    if (form.legal_aid && typeof form.legal_aid !== 'boolean') {
      errors.legal_aid = t('errors.invalidLegalAidValue');
    }

    return errors;
  };

  const handleRemoveParticipant = useCallback((participant) => {
    setAlertTitle(t("messages.removeParticipantConfirmation"));
    setAlertBody(t("messages.removeParticipantWarning", { name: participant.name }));
    const participantIds = data.participants.map(
      (participant) => participant.id
    );
    const updatedParticipants = participantIds.filter(
      (id) => id !== participant.id
    );
    // console.log("handleRemoveParticipant", updatedParticipants);
    setAlertConfirmCallback(() => async () => {
      try {
        const participantIds = data.participants.map(
          (participant) => participant.id
        );
        const updatedParticipants = participantIds.filter(
          (id) => id !== participant.id
        );

        await rdvService.deleteParticipant({
          pk: data.id,
          participantId: participant.id,
          updatedParticipants: {
            participants: updatedParticipants,
          },
          accessToken,
        });

        toast.success(`${t("messages.participantRemoved")}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refresh();
      } catch (error) {
        console.error("Failed to remove participant:", error);
        toast.error(`${t("messages.failedToRemoveParticipant")}`, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      onAlertClose();
    });
    onAlertOpen();
  }, [data?.id, accessToken, refresh, t, onAlertClose, onAlertOpen]);

  const openModal = useCallback((participant = null) => {
    setCurrentParticipant(participant);
    onModalOpen();
  }, [onModalOpen]);

  // useEffect(() => {
  //   console.log("data", data.participants)
  // }, []);

  // Ensure data is available before rendering
  if (!data) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" mb={3}>
      <Flex justify="space-between" p={4} borderBottomWidth="1px">
        <Text fontSize="2xl" fontWeight="semibold">{t("admin__appointments_table__participants")}</Text>
        {data.is_closed ? ''
          : <Button fontSize={"16px"}
            fontWeight={"500"}
            marginLeft={3}
            marginY={"auto"}
            color={"#FF6666"}
            bg={"none"} onClick={() => openModal()}
            isDisabled={data.is_closed}>
            {t("admin__appointments_table__add_participant")}
          </Button>}
      </Flex>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__name")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__status")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__email")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__telephone")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__city")}</Th>
            <Th sx={tableHeaderStyles}>{t("admin__appointments_table__average_monthly_income")}</Th>
            <Th sx={tableHeaderStyles} style={{ display: association.slug === 'kiido' ? 'none' : 'block' }}>{t("admin__appointments_table__amount_per_session")}</Th>
            <Th sx={tableHeaderStyles} style={{ display: association.slug === 'kiido' ? 'none' : 'block' }}>{t("admin__appointments_table__regime")}</Th>
            <Th sx={tableHeaderStyles}>{t("actions")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.participants.length === 0 ? (
            <Tr>
              <Td colSpan={5}>
                <Text textAlign="center" color="gray.500">{t("no_participant_added")}</Text>
              </Td>
            </Tr>
          ) : (
            data.participants.map((participant) => (
              <Tr key={participant.id}>
                <Td sx={tableContentStyles}>{participant?.name}</Td>
                <Td sx={tableContentStyles} textTransform="capitalize">{t(`family_relation__${participant?.status}`)}</Td>
                <Td sx={tableContentStyles}>{participant?.email}</Td>
                <Td sx={tableContentStyles}>{participant?.phone}</Td>
                <Td sx={tableContentStyles}>{participant?.city}</Td>
                <Td sx={tableContentStyles}>{participant?.average_monthly_income ? `${participant?.average_monthly_income}Є` : ""}</Td>
                <Td sx={tableContentStyles} style={{ display: association.slug === 'kiido' ? 'none' : 'block' }} >{participant?.amount_per_session ? `${participant?.amount_per_session}Є` : ""}</Td>
                <Td sx={tableContentStyles} style={{ display: association.slug === 'kiido' ? 'none' : 'block' }} >{participant?.regime === 'AUTRE' ? t('autre') : participant?.regime}</Td>
                <Td sx={tableContentStyles}>
                  <IconButton
                    aria-label="Edit participant"
                    icon={<EditIcon />}
                    size="sm"
                    mr={2}
                    isDisabled={data.is_closed}
                    onClick={() => openModal(participant)}
                  />
                  <IconButton
                    aria-label="Remove participant"
                    icon={<DeleteIcon />}
                    size="sm"
                    colorScheme="red"
                    isDisabled={data.is_closed}
                    onClick={() => handleRemoveParticipant(participant)}
                  />
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {isModalOpen ?
        <ParticipantModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          initialRef={initialParticipantRef}
          finalRef={finalParticipantRef}
          addParticipant={handleAddParticipant}
          editParticipant={handleEditParticipant}
          participant={currentParticipant}
        />
        : ''}
      {isAlertOpen ?
        <GenericAlertDialog
          isOpen={isAlertOpen}
          onClose={onAlertClose}
          title={alertTitle}
          body={alertBody}
          confirm={alertConfirmCallback}
        /> : ''}
    </Box>
  );
};

export default ParticipantSection;
