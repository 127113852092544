// MediationManagement Component
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../../../actions/authActions"; // Ensure you have an action creator for logging out
import {
  Text,
  Flex,
  Box,
  Icon,
  useColorModeValue,
  Tag,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import BrandHeader from "components/menu/BrandHeader";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../actions/collaboratorActions";
import { getData, getLoading, getError } from "../../../selectors/collaboratorSelector";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { selectUser, selectAccessToken, selectProfessionalProfile, selectAssociation } from "../../../selectors/authSelector";
import rdvService from "../../../services/rdvService";
import AppointmentDetails from "./components/AppointmentDetails";

const MediationManagement = ({ user, accessToken, professional_profile, association }) => {
  const [mediatorOptions, setMediatorOptions] = useState([]);
  const dispatch = useDispatch();
  const collaboratorsData = useSelector(getData);

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  useEffect(() => {
    setMediatorOptions(collaboratorsData);
  }, [collaboratorsData]);

  const { appointmentId } = useParams();
  const [notes, setNotes] = useState("You can add notes here. Notes are auto saved and cannot be edited later.");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const history = useHistory();

  const fetchRDVData = async () => {
    const id = appointmentId
    try {
      const res = await rdvService.sessionDetailsCall({ accessToken, id });
      // Check if res.data is valid and handle redirects accordingly
      if (!res.data) {
        dispatch(logout());
        history.push('/auth/sign-in');
        return; // Exit the function after redirect
      }

      // Check if original_rdv and rdv_sessions are present
      if (!res.data.original_rdv || !res.data.original_rdv.rdv_sessions) {
        history.push('/admin/default');
        return; // Exit the function after redirect
      }
      // Normalize the session status based on postpone_count
      const normalizedData = {
        ...res.data,
        original_rdv: {
          ...res.data.original_rdv,
          rdv_sessions: res.data.original_rdv.rdv_sessions.map(session => {
            if (session.status === "Scheduled" && session.postpone_count > 0) {
              return { ...session, status: "Postponed" };
            }
            return session;
          }),
        }
      };

      console.log("normalizedData", normalizedData);
      setData(normalizedData);
      const originId = res.data.original_rdv

      console.log("response fetch data ", res.data)
    } catch (err) {
      console.log("medaitor s err", err);
      if (err.status === 404 || err.response.data.detail === "Not found" || err.response.status === 404) {
        history.push('/admin/default');
      }
      // dispatch(logout());
      // history.push('/admin/default');

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!appointmentId) return;
    fetchRDVData();
  }, [appointmentId]);

  return (
    <>
      <BrandHeader />
      <ToastContainer />
      {error && <Text>{error.message}</Text>}
      {data && (
        <AppointmentDetails
          data={data}
          collaboratorsData={collaboratorsData}
          accessToken={accessToken}
          onStateChange={() => fetchRDVData()}
          professional_profile={professional_profile}
          association={association}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: selectUser(state),
  accessToken: selectAccessToken(state),
  professional_profile: selectProfessionalProfile(state),
  association: selectAssociation(state)
});

export default connect(mapStateToProps)(MediationManagement);
