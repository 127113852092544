import React, { useEffect } from "react";
import { Flex, Input, Button, Select, Box } from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import FilterIcon from "../../../../assets/img/dashboards/filter_icon.svg";
registerLocale("fr", fr);

const selectStyles = {
  backgroundColor: "rgba(86, 86, 86, 15%)",
  borderRadius: "12px",
  color: "gray.900",
  marginLeft: "10px",
};

const datePickerStyles = {
  ...selectStyles,
  padding: "8px 12px",
  border: "none",
  width: "150px",
  marginRight: "10px",
  _placeholder: { color: "black", fontSize: "" },
};

const SearchAndFilter = ({
  filters,
  setFilters,
  searchType,
  setSearchType,
  searchQuery,
  setSearchQuery,
  handleSearch,
  locationsList,
  handleResetSearchAndFilter,
}) => {
  const { t } = useTranslation();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Handle side effect to load preselected data from parent
  useEffect(() => {
    if (
      filters.type ||
      filters.status ||
      filters.location ||
      filters.from ||
      filters.to
    ) {
      handleSearch();
    }
  }, [filters, handleSearch]);

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
    setSearchQuery('')
  };

  // Function to get today's date + 1 week
  const getNextWeekDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 7); // Add 7 days to the current date
    return new Date(today.getTime() - (today.getTimezoneOffset() * 60000)); // Adjust for local timezone
  };

  return (
    <>
      <Box
        marginY={"10px"}
        width={"100%"}
        border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
      >
        <Flex alignItems={"center"} justifyContent="space-around">
          <SearchIcon fontSize={"2xl"} marginX={4} onClick={handleSearch} />
          <Select
            value={searchType}
            onChange={handleSearchTypeChange}
            width="auto"
            marginRight={4}
          >
            <option value="participant_name">{t("admin_filters.names")}</option>
            <option value="file_number">{t("admin_filters.file_numbers")}</option>
            <option value="participant_email">{t("admin_filters.emails")}</option>
          </Select>
          <Input
            placeholder={t("admin_filters.search")}
            style={{ width: "77%" }}
            value={searchQuery} // Adjusted to match filters state
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Box width={"10%"}></Box>
        </Flex>
      </Box>
      <Box
        width={"100%"}
        border="1px"
        borderColor="gray.300"
        borderRight={"none"}
        paddingY={2}
        borderTop={"none"}
      >
        <Flex alignItems="center">
          <Box marginLeft={4}>
            <img
              style={{ width: "30px", height: "30px" }}
              src={FilterIcon}
              alt="filter"
            />
          </Box>
          <Select
            placeholder={t("placeholder.status")}
            sx={selectStyles}
            width={"auto"}
            marginRight={4}
            value={filters.status}
            onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
          >
            <option value="Created">{t("status_options.created")}</option>
            <option value="Scheduled">{t("status_options.scheduled")}</option>
            <option value="Occurred">{t("status_options.occurred")}</option>
            <option value="Cancelled">{t("status_options.cancelled")}</option>
            <option value="Late Cancel">{t("status_options.late_cancel")}</option>
          </Select>
          <Select
            // placeholder={t("placeholder.type")}
            sx={selectStyles}
            value={filters.type}
            onChange={(e) => setFilters(prev => ({ ...prev, type: e.target.value }))}
            width="auto"
            marginRight={4}
          >
            <option value="">{t("session_type_search_dd.all")}</option>
            <option value="Info">{t("session_type_search_dd.info")}</option>
            <option value="Mediation">{t("session_type_search_dd.mediation")}</option>
          </Select>
          <Select
            placeholder={t("placeholder.location")}
            sx={selectStyles}
            width={"auto"}
            marginRight={4}
            value={filters.location}
            onChange={(e) => setFilters(prev => ({ ...prev, location: e.target.value }))}
          >
            {locationsList?.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </Select>
          <DatePicker
            selected={filters.from}
            onChange={(date) => setFilters(prev => ({ ...prev, from: date.toISOString().split('T')[0] }))}
            selectsStart
            value={filters.from}
            // startDate={fromDate}
            // endDate={toDate}
            placeholderText={t("admin_filters.from_date")}
            customInput={<Input sx={datePickerStyles} />}
            showPopperArrow={true}
            // locale="fr"
            dateFormat="dd-MM-yyyy"
          />
          <DatePicker
            selected={filters.to}
            onChange={(date) => {
              if (date) {
                // Adjust the date for local timezone offset
                const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                setFilters(prev => ({ ...prev, to: localDate.toISOString().split('T')[0] }));
              }
            }}
            selectsEnd
            value={filters.to || getNextWeekDate().toISOString().split('T')[0]} // Show date 1 week from today by default
            placeholderText={t("admin_filters.to_date")}
            customInput={<Input sx={datePickerStyles} />}
            showPopperArrow={true}
            // locale="fr"
            dateFormat="dd-MM-yyyy"
          />
          <CloseIcon
            mr={6}
            ml={"auto"}
            cursor="pointer"
            onClick={handleResetSearchAndFilter}
          />
        </Flex>
      </Box>
    </>
  );
};

export default SearchAndFilter;
