import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import DocumentSection from "./DocumentSection";
import ChildSection from "./ChildSection";
import ParticipantSection from "./ParticipantSection";
import { useTranslation } from "react-i18next";
import rdvService from "services/rdvService";
import Utils from "services/Utils";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";

const RequestDetails = ({ data, collaboratorsData, accessToken, association, refresh }) => {
  const { t, i18n } = useTranslation();
  const [legalMode, setLegalMode] = useState(data.nature);
  const [contextJurid, setContextJurid] = useState(data.legal_context);
  const [subject, setSubject] = useState(data.subject);
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [alertDialogOnClose, setAlertDialogOnClose] = useState(() => () => { });
  const [alertDialogConfirm, setAlertDialogConfirm] = useState(() => () => { });
  const finalParticipantRef = useRef(null);
  const finalChildrenRef = useRef(null);
  const initialDocumentsRef = useRef(null);
  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose,
  } = useDisclosure();

  const [isConvetionnelMode, setIsConvetionnelMode] = useState(false);

  const openGenericAlertDialog = (title, body, confirm, close) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(body);
    setAlertDialogConfirm(() => confirm);
    setAlertDialogOnClose(() => close);
    onAlertDialogOpen();
  };

  const handleConfirm = () => {
    alertDialogConfirm();
    onAlertDialogClose();
  };

  const handleClose = () => {
    alertDialogOnClose();
    onAlertDialogClose();
  };
  const startTime = data?.calendar_block?.start_date
    ? new Date(data.calendar_block?.start_date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
    : "N/A";

  const endTime = data?.calendar_block?.end_date
    ? new Date(data.calendar_block?.end_date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
    : "N/A";

  console.log("data*********************************", data)

  return (
    <Box height="100%" overflowY="auto" paddingX={4} marginX={4}>
      {/* <Button ref={finalParticipantRef} hidden>
        Focus anchor for participant modal
      </Button>
      <Button ref={finalChildrenRef} hidden>
        Focus anchor for children modal
      </Button> */}
      <Flex direction={"column"} width={"100%"} paddingBottom={5}>
        <FormControl
          // ml={4}
          // marginY={5}
          // margin={3}
          // width={"100%"}
          // isDisabled={data.is_closed}
          mb={3}
        >
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments__status")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Box ml={1} mb={5}>
            {AppointmentStatusLabel(data.original_rdv, t)}
          </Box>

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__file_number")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="2445524"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data?.original_rdv?.file_number || "N/A"}
            contentEditable={false}
            readOnly
          />

          <Flex
            direction="row"
            width="100%"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box width="30%" mb={5}>
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__location")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Input
                isRequired={true}
                bg={"gray.100"}
                width={"100%"}
                type="title"
                placeholder="Location"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                value={data?.location?.name || "N/A"}
                contentEditable={false}
                readOnly
              />
            </Box>
            <Box width="30%" mb={5}>
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__address")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Input
                isRequired={true}
                bg={"gray.100"}
                width={"100%"}
                type="title"
                placeholder="Location"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                value={data?.location?.address || "N/A"}
                contentEditable={false}
                readOnly
              />
            </Box>
            <Box width="30%" mb={5}>
              <FormLabel
                fontSize={"16px"}
                color={"#252525"}
                fontWeight={"400"}
                display={"flex"}
              >
                {t("admin__appointments_table__department")}
                <Text ml={1} color={"#EE0000"}>
                  *
                </Text>
              </FormLabel>
              <Input
                isRequired={true}
                bg={"gray.100"}
                width={"100%"}
                type="title"
                placeholder="Location"
                fontSize={"14px"}
                fontWeight={"400"}
                borderRadius="4px"
                value={data?.location?.department || "N/A"}
                contentEditable={false}
                readOnly
              />
            </Box>
          </Flex>

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {association.slug === 'kiido' ? t("admin__header__services") : t("admin__appointments_table__service")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="Mediation"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            // value={data?.type || "N/A"}
            value={
              association?.slug === 'kiido'
                ? (i18n.language === 'fr'
                  ? data.original_rdv?.service?.name_fr
                  : data.original_rdv?.service?.name_en)
                : data?.type ? data?.type : 'N/A'
            }
            mb={5}
            contentEditable={false}
            readOnly
          />

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {association.slug === 'kiido' ? t("session_theme") : t("admin__appointments_table__service")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="Mediation"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            // value={data?.type || "N/A"}
            value={
              association?.slug === 'kiido'
                ? (i18n.language === 'fr'
                  ? data.original_rdv?.service?.concerns[0].name_fr
                  : data.original_rdv?.service?.concerns[0].name_en)
                : data?.type ? data?.type : 'N/A'
            }
            mb={5}
            contentEditable={false}
            readOnly
          />


          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__session_date")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="14 April 2024"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={data?.calendar_block?.start_date ?
              Utils.convertUTCToLocalDate(
                new Date(data?.calendar_block?.start_date)
                  .toISOString()
                  .split("T")[0]
              ) || "N/A"
              : ''}
            contentEditable={false}
            readOnly
          />
          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__session_time")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            placeholder="11:30"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            // value={startTime !== 'N/A' && endTime !== 'N/A'
            //   ? `${startTime} - ${endTime}`
            //   : 'N/A'}
            value={data.calendar_block?.start_date ?
              `${Utils.convertUTCToLocalTimeZone(data.calendar_block?.start_date.split("T")[1].slice(0, -4))}` ||
              "N/A"
              : ''}
            contentEditable={false}
            readOnly
            color={"#000000"}
          />

          <FormLabel
            fontSize={"16px"}
            color={"#252525"}
            fontWeight={"400"}
            display={"flex"}
          >
            {t("admin__appointments_table__supplementary_details")}
            <Text ml={1} color={"#EE0000"}>
              *
            </Text>
          </FormLabel>
          <Input
            isRequired={true}
            bg={"gray.100"}
            width={"100%"}
            type="title"
            fontSize={"14px"}
            fontWeight={"400"}
            borderRadius="4px"
            mb={5}
            value={
              data.addtional_info
                ? data.addtional_info
                : t("no_addional_info_provided")
            }
            contentEditable={false}
            readOnly
            color={"#000000"}
          />
          <Box style={{ display: association.slug === 'kiido' ? 'none' : 'block' }}>
            <FormLabel
              fontSize={"16px"}
              color={"#252525"}
              fontWeight={"400"}
              display={"flex"}
            >
              {t("admin__appointments_table__principle_mediator")}
              <Text ml={1} color={"#EE0000"}>
                *
              </Text>
            </FormLabel>
            <Input
              isRequired={true}
              bg={"gray.100"}
              width={"100%"}
              type="title"
              placeholder=""
              fontSize={"14px"}
              fontWeight={"400"}
              color="black"
              borderRadius="4px"
              mb={5}
              value={
                data.service_provider !== null
                  ? `${data.service_provider.first_name} ${data.service_provider.last_name}`
                  : t("unassigned")
              }
              contentEditable={false}
              readOnly
            />

            <Flex>
              <Box width="50%">
                <FormLabel
                  fontSize={"16px"}
                  color={"#252525"}
                  fontWeight={"400"}
                  display={"flex"}
                >
                  {t("admin__appointments_table__legal_mode")}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  bg={"gray.100"}
                  isRequired={true}
                  width={"100%"}
                  placeholder={t("select_option")}
                  fontSize={"14px"}
                  fontWeight={"400"}
                  borderRadius="4px"
                  mb={5}
                  value={data.original_rdv?.nature}
                  isDisabled={data.original_rdv.is_closed}
                  onChange={(e) => {
                    setLegalMode(e.target.value);
                    const patchPayload = {
                      nature: e.target.value,
                    };
                    if (patchPayload.nature === "Conventionnel") {
                      patchPayload.legal_context = "None";
                      setIsConvetionnelMode(true);
                    } else {
                      setIsConvetionnelMode(false);
                    }
                    rdvService
                      .update({
                        pk: data.original_rdv?.id,
                        accessToken: accessToken,
                        obj: patchPayload,
                      })
                      .then((res) => {
                        refresh();
                        toast.success(
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <FaCheck style={{ marginRight: "8px" }} />
                            {t("messages.changesSaved")}
                          </div>,
                          {
                            position: "bottom-center",
                            style: {
                              backgroundColor: "#FF6666",
                              color: "white",
                              width: "auto",
                              minWidth: "400px",
                            },
                            icon: false,
                          }
                        );
                      });
                  }}
                >
                  <option value="Conventionnel">
                    {t("legal_mode__conventionnel")}
                  </option>
                  <option value="Judiciaire">
                    {t("legal_mode__judiciaire")}
                  </option>
                  <option value="TMFPO">{t("legal_mode__tmfpo")}</option>
                </Select>
              </Box>
              <Box ml={5} width="50%">
                <FormLabel
                  fontSize={"16px"}
                  color={"#252525"}
                  fontWeight={"400"}
                  display={"flex"}
                >
                  {t("admin__appointments_table__context_jurid")}
                  <Text ml={1} color={"#EE0000"}>
                    *
                  </Text>
                </FormLabel>
                <Select
                  isRequired={true}
                  bg={"gray.100"}
                  width={"100%"}
                  placeholder={t("select_option")}
                  fontSize={"14px"}
                  fontWeight={"400"}
                  borderRadius="4px"
                  mb={5}
                  isDisabled={data.nature === "Conventionnel" || isConvetionnelMode || data.original_rdv.is_closed}
                  value={data.original_rdv?.legal_context}
                  onChange={(e) => {
                    setContextJurid(e.target.value);
                    rdvService
                      .update({
                        pk: data.original_rdv?.id,
                        accessToken: accessToken,
                        obj: {
                          legal_context: e.target.value,
                        },
                      })
                      .then((res) => {
                        refresh();
                        toast.success(
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <FaCheck style={{ marginRight: "8px" }} />
                            {t("messages.changesSaved")}
                          </div>,
                          {
                            position: "bottom-center",
                            style: {
                              backgroundColor: "#FF6666",
                              color: "white",
                              width: "auto",
                              minWidth: "400px",
                            },
                            icon: false,
                          }
                        );
                      });
                  }}
                >
                  <option value="Double convocation">
                    {t("legal_context__double_convocation")}
                  </option>
                  <option value="Injonction">
                    {t("legal_context__injonction")}
                  </option>
                  <option value="Ordonnance">
                    {t("legal_context__ordonnance")}
                  </option>
                  <option value="Cadre judiciaire sur invitation écrite de la Juridiction">
                    {t("legal_context__cadre_judiciaire")}
                  </option>
                  <option value="TMFPO">{t("legal_context__tmfpo")}</option>
                </Select>
              </Box>
            </Flex>
            <FormLabel
              fontSize={"16px"}
              color={"#252525"}
              fontWeight={"400"}
              display={"flex"}
            >
              {t("admin__appointments_table__subject")}
              <Text ml={1} color={"#EE0000"}>
                *
              </Text>
            </FormLabel>
            <Select
              bg={"gray.100"}
              isRequired={true}
              width={"100%"}
              placeholder="Select option"
              fontSize={"14px"}
              fontWeight={"400"}
              borderRadius="4px"
              mb={5}
              value={data.original_rdv?.subject}
              isDisabled={data.original_rdv?.is_closed}
              onChange={(e) => {
                setSubject(e.target.value);
                rdvService
                  .update({
                    pk: data.id,
                    accessToken: accessToken,
                    obj: {
                      subject: e.target.value,
                    },
                  })
                  .then((res) => {
                    toast.success(`${t("messages.changesSaved")}`, {
                      position: "bottom-center",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  });
                refresh();
              }}
            >
              <option value={t("family_topic__decision_support")}>
                {t("family_topic__decision_support")}
              </option>
              <option value={t("family_topic__custody_arrangement_coparenting")}>
                {t("family_topic__custody_arrangement_coparenting")}
              </option>
              <option
                value={t("family_topic__education_maintenance_contribution")}
              >
                {t("family_topic__education_maintenance_contribution")}
              </option>
              <option value={t("family_topic__other_miscellaneous_situations")}>
                {t("family_topic__other_miscellaneous_situations")}
              </option>
              <option value={t("family_topic__same_sex_family")}>
                {t("family_topic__same_sex_family")}
              </option>
              <option value={t("family_topic__blended_family")}>
                {t("family_topic__blended_family")}
              </option>
              <option value={t("family_topic__siblings")}>
                {t("family_topic__siblings")}
              </option>
              <option
                value={t("family_topic__grandparents_children_grandchildren")}
              >
                {t("family_topic__grandparents_children_grandchildren")}
              </option>
              <option value={t("family_topic__parents_teenagers")}>
                {t("family_topic__parents_teenagers")}
              </option>
              <option value={t("family_topic__parents_young_adults")}>
                {t("family_topic__parents_young_adults")}
              </option>
              <option value={t("family_topic__separation_divorce")}>
                {t("family_topic__separation_divorce")}
              </option>
            </Select>
          </Box>
        </FormControl>

        <ParticipantSection
          data={data.original_rdv}
          accessToken={accessToken}
          refresh={refresh}
          association={association}
        />
        <Box style={{ display: association.slug === 'kiido' ? 'none' : 'block' }}>
          <ChildSection
            data={data.original_rdv}
            accessToken={accessToken}
            refresh={refresh}
            t={t}
          />
        </Box>
        <DocumentSection
          data={data.original_rdv}
          accessToken={accessToken}
          refresh={refresh}
          t={t}
          association={association}
        />
      </Flex>
    </Box>
  );
};

function AppointmentStatusLabel(appointment, t) {

  // By default consider the appointment as "In Progress"
  let status = t("admin__appointments__in_progress");
  let statusColor = "#199CD6";
  let statusBgColor = "#E4F5FC";

  // If principal mediator is not assigned, then the appointment is "New"
  if (appointment.mediator === null) {
    status = t("admin__appointments__new");
    statusColor = "#FF6666";
    statusBgColor = "#FFEEEE";
  }

  // switch (appointment) {
  //   case "Scheduled":
  //     status = t("status_options.scheduled");
  //     statusColor = "#FF6666";
  //     statusBgColor = "#FFEEEE";
  //     break;
  //   case "Occurred":
  //     status = t("status_options.occurred");
  //     statusColor = "#9FB80A";
  //     statusBgColor = "#F7F9ED";
  //     break;
  //   case "Cancelled":
  //     status = t("status_options.cancelled");
  //     statusColor = "#F35B5B";
  //     statusBgColor = "#FBE9E9";
  //     break;
  //   case "Late Cancel":
  //     status = t("status_options.late_cancel");
  //     statusColor = "#F35B5B";
  //     statusBgColor = "#FBE9E9";
  //     break;
  //   case "Created":
  //     status = t("status_options.created");
  //     statusColor = "#199CD6";
  //     statusBgColor = "#E4F5FC";
  //     break;
  //   default:
  //     status = t("status_options.completed");
  // }


  // If the appointment is_closed (completed), then the status is "Completed"
  if (appointment.is_closed) {
    status = t("status_options.completed");
    statusColor = "#9FB80A";
    statusBgColor = "#F7F9ED";
  }

  return (
    <Box
      color={statusColor}
      bgColor={statusBgColor}
      borderRadius={"8px"}
      padding={"4px 8px"}
      textAlign={"center"}
      width={"fit-content"}
    >
      {status}
    </Box>
  );
}

export default RequestDetails;
