import React, { useEffect, useState } from 'react';
import BrandHeader from 'components/menu/BrandHeader';
import { connect } from 'react-redux';
import { selectAccessToken, selectProfessionalProfile } from 'selectors/authSelector';
import { Box, Button, Circle, Flex, Heading, IconButton, Image, Select, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'selectors/authSelector';
import { selectAssociation } from 'selectors/authSelector';
import FilterTransactions from './components/SearchAndFilterTransaction';
import ToFirstPage from "../../../assets/img/dashboards/first.svg";
import ToLastPage from "../../../assets/img/dashboards/last.svg";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import rdvService from 'services/rdvService';

const Index = ({ user, accessToken, association, professional_profile }) => {
    const { t } = useTranslation();
    const [transactionsAllData, setTransactionsAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        status: "",
        from: null,
        to: null
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [searchType, setSearchType] = useState('file_number');

    const tableHeaderStyles = {
        textAlign: "start",
        fontSize: "16px",
        color: "#252525",
    };

    const tableContentStyles = {
        fontSize: "16px",
        fontWeight: "400",
        color: "#565656",
    };



    // Construct query string based on filters
    const constructQueryString = () => {
        let query = '?'

        if (filters.status) {
            query += `payment_status=${filters.status}`;

        }
        if (searchQuery) query += `&${searchType}=${searchQuery}`;
        return query;
    };
    // Get all transactions list
    const getAllTransactionsList = async () => {
        const queryString = constructQueryString();
        console.log("*** queryString ***", searchType, searchQuery)
        setLoading(true);
        try {
            const res = await rdvService.transactionsListAll(accessToken, queryString);
            console.log("getAllTransactionsList:", res?.data);
            setTransactionsAllData(res?.data);
        } catch (err) {
            console.log("err err err", err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllTransactionsList();
    }, [filters, searchQuery]);

    return (
        <div>
            <BrandHeader />
            <Flex
                direction={"column"}
                margin={3}
                border="1px"
                borderColor="gray.300"
                borderRadius={"8px"}
            >
                <Flex justifyContent={"space-between"} borderBottom="1px" borderColor="gray.300">
                    <Box
                        fontSize={"24px"}
                        fontWeight={"500"}
                        marginLeft={3}
                        marginY={"12px"}
                    >
                        {t('transactions.transactions')}
                    </Box>
                </Flex>

                <FilterTransactions
                    filters={filters}
                    setFilters={setFilters}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchType={searchType}
                    setSearchType={setSearchType}
                    handleSearch={getAllTransactionsList}
                    handleResetSearchAndFilter={() => {
                        setFilters({
                            status: "",
                            from: null,
                            to: null
                        });
                        setSearchQuery('');
                        setSearchType('file_number');
                    }}
                />

                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th sx={tableHeaderStyles}>
                                    {t('transactions.file_number')}
                                </Th>
                                <Th sx={tableHeaderStyles}>
                                    {t('transactions.amount')}
                                </Th>
                                <Th sx={tableHeaderStyles}>
                                    {t('transactions.Session_name')}
                                </Th>
                                <Th sx={tableHeaderStyles}>
                                    {t('transactions.payment_method')}
                                </Th>
                                <Th sx={tableHeaderStyles}>
                                    {t('transactions.status')}
                                </Th>
                                <Th sx={tableHeaderStyles}>
                                    {t('transactions.customer')}
                                </Th>
                                <Th sx={tableHeaderStyles}>
                                    {t('transactions.payment_date')}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {!loading ? (
                                transactionsAllData.length > 0 ? (
                                    transactionsAllData.map((data, index) => {
                                        const localDate = data?.payment_received_at ? new Date(data.payment_received_at) : null;
                                        const formattedDate = localDate
                                            ? new Intl.DateTimeFormat('default', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true,
                                            }).format(localDate)
                                            : '-';

                                        return (
                                            <Tr key={index}>
                                                <Td sx={tableContentStyles}>{data?.file_number}</Td>
                                                <Td sx={tableContentStyles}>
                                                    {data?.transaction_amount}{' '}
                                                    <Text as="span" textTransform="uppercase">
                                                        {data?.transaction_currency}
                                                    </Text>
                                                </Td>
                                                <Td sx={tableContentStyles} textTransform="capitalize">
                                                    {data?.session_name || '-'}
                                                </Td>
                                                <Td sx={tableContentStyles} textTransform="capitalize">
                                                    {data?.payment_method || '-'}
                                                </Td>
                                                <Td sx={tableContentStyles}>
                                                    {t(`transactions.${data?.payment_status?.toLowerCase()}`, { defaultValue: data?.payment_status }) || '-'}
                                                </Td>

                                                <Td sx={tableContentStyles} textTransform="capitalize">
                                                    {data?.participant_names || '-'}
                                                </Td>
                                                <Td sx={tableContentStyles}>{formattedDate}</Td>
                                            </Tr>
                                        );
                                    })
                                ) : (
                                    <Tr>
                                        <Td colSpan={7} textAlign="center">
                                            <Text color="gray" fontSize="md" padding={5}>
                                                {t('transactions.no_transactions_found')}
                                            </Text>
                                        </Td>
                                    </Tr>
                                )
                            ) : (
                                <Tr>
                                    <Td colSpan={7} textAlign="center">
                                        <Spinner size="lg" thickness="3px" color="#FF6666" />
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: selectUser(state),
    accessToken: selectAccessToken(state),
    association: selectAssociation(state),
    professional_profile: selectProfessionalProfile(state),
});

export default connect(mapStateToProps)(Index);
