import axios from 'axios';
import { store } from './store';
import authService from './services/authService';
import { updateAccessToken, logout, refreshToken } from './actions/authActions';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.request.use(
  (config) => {
    console.log('Request interceptor called');
    const state = store.getState();
    const accessToken = state.auth.accessToken;
    // const accessToken = `ksjdhfjkdfhskljdhfkhaskjdfl`
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.error('Request interceptor error', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Response interceptor called. No problem found');
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log(error);
    console.log('Response error interceptor called');
    if (error.response && error.response.status === 401) {
      console.log('401 error detected');

      if (!originalRequest._retry) {
        console.log('!originalRequest._retry')
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({resolve, reject});
          }).then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axiosInstance(originalRequest);
          }).catch(err => {
            console.error('Error in queue processing', err);
            return Promise.reject(err);
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const state = store.getState();
          const refreshTokenValue = state.auth.refreshToken;
          console.log('From axios config, refreshToken:', refreshTokenValue);

          if (!refreshTokenValue) {
            console.log('No refresh token available, logging out');
            store.dispatch(logout());
            processQueue(new Error('No refresh token available'));
            return Promise.reject(error);
          }

          console.log('Calling for new refresh token');
          const newAccessToken = await authService.refreshToken(refreshTokenValue);
          console.log('New access token received:', newAccessToken);

          // Dispatch action to update access token in Redux store
          store.dispatch(refreshToken(newAccessToken));

          processQueue(null, newAccessToken);
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error('Error refreshing token', refreshError);
          store.dispatch(logout());
          processQueue(refreshError);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }
    }

    console.error('Other error in response interceptor', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;