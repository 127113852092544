import React from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Inject, Month } from '@syncfusion/ej2-react-schedule';
import "../css/MonthCalendar.css"
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import { useTranslation } from 'react-i18next';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/fr/ca-gregorian.json';
import * as numbers from 'cldr-data/main/fr/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json'

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  "en": {
    "schedule": {
      "day": "Day",
      "week": "Week",
      "workWeek": "Work Week",
      "month": "Month",
      "year": "Year",
      "agenda": "Agenda",
      "weekAgenda": "Week Agenda",
      "workWeekAgenda": "Work Week Agenda",
      "monthAgenda": "Month Agenda",
      "today": "Today",
      "noEvents": "No events",
      "emptyContainer": "There are no events scheduled on this day.",
      "allDay": "All day",
      "start": "Start",
      "end": "End",
      "more": "more",
      "close": "Close",
      "cancel": "Cancel",
      "noTitle": "(No Title)",
      "delete": "Delete",
      "deleteEvent": "Delete Event",
      "deleteMultipleEvent": "Delete Multiple Events",
      "selectedItems": "Items selected",
      "deleteSeries": "Delete Series",
      "edit": "Edit",
      "editSeries": "Edit Series",
      "editEvent": "Edit Event",
      "createEvent": "Create",
      "subject": "Subject",
      "addTitle": "Add title",
      "moreDetails": "More Details",
      "moreEvents": "More Events",
      "save": "Save",
      "editContent": "Do you want to edit only this event or entire series?",
      "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
      "deleteContent": "Are you sure you want to delete this event?",
      "deleteMultipleContent": "Are you sure you want to delete the selected events?",
      "newEvent": "New Event",
      "title": "Title",
      "location": "Location",
      "description": "Description",
      "timezone": "Timezone",
      "startTimezone": "Start Timezone",
      "endTimezone": "End Timezone",
      "repeat": "Repeat",
      "saveButton": "Save",
      "cancelButton": "Cancel",
      "deleteButton": "Delete",
      "recurrence": "Recurrence",
      "wrongPattern": "The recurrence pattern is not valid.",
      "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
      "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
      "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
      "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
      "occurenceAlert": "Cannot reschedule an occurrence of the recurring appointment if it skips over a later occurrence of the same appointment.",
      "editRecurrence": "Edit Recurrence",
      "repeats": "Repeats",
      "alert": "Alert",
      "startEndError": "The selected end date occurs before the start date.",
      "invalidDateError": "The entered date value is invalid.",
      "blockAlert": "Events cannot be scheduled within the blocked time range.",
      "ok": "Ok",
      "yes": "Yes",
      "no": "No",
      "occurrence": "Occurrence",
      "series": "Series",
      "previous": "Previous",
      "next": "Next",
      "timelineDay": "Timeline Day",
      "timelineWeek": "Timeline Week",
      "timelineWorkWeek": "Timeline Work Week",
      "timelineMonth": "Timeline Month",
      "expandAllDaySection": "Expand",
      "collapseAllDaySection": "Collapse",
      "timelineYear": "Timeline Year",
      "editFollowingEvent": "Following Events",
      "deleteTitle": "Delete Event",
      "editTitle": "Edit Event",
      "beginFrom": "Begin From",
      "endAt": "End At",
      "searchTimezone": "Search Timezone",
      "noRecords": "No records found",
    },
    "recurrenceeditor": {
      "none": "None",
      "daily": "Daily",
      "weekly": "Weekly",
      "monthly": "Monthly",
      "month": "Month",
      "yearly": "Yearly",
      "never": "Never",
      "until": "Until",
      "count": "Count",
      "first": "First",
      "second": "Second",
      "third": "Third",
      "fourth": "Fourth",
      "last": "Last",
      "repeat": "Repeat",
      "repeatEvery": "Repeat Every",
      "on": "Repeat On",
      "end": "End",
      "onDay": "Day",
      "days": "Day(s)",
      "weeks": "Week(s)",
      "months": "Month(s)",
      "years": "Year(s)",
      "every": "every",
      "summaryTimes": "time(s)",
      "summaryOn": "on",
      "summaryUntil": "until",
      "summaryRepeat": "Repeats",
      "summaryDay": "day(s)",
      "summaryWeek": "week(s)",
      "summaryMonth": "month(s)",
      "summaryYear": "year(s)"
    }
  },
  "fr": {
    "schedule": {
      "day": "Jour",
      "week": "Semaine",
      "workWeek": "Semaine de travail",
      "month": "Mois",
      "year": "Année",
      "agenda": "Agenda",
      "weekAgenda": "Agenda de la semaine",
      "workWeekAgenda": "Agenda de la semaine de travail",
      "monthAgenda": "Agenda du mois",
      "today": "Aujourd'hui",
      "noEvents": "Aucun événement",
      "emptyContainer": "Il n'y a aucun événement prévu ce jour-là.",
      "allDay": "Toute la journée",
      "start": "Début",
      "end": "Fin",
      "more": "plus",
      "close": "Fermer",
      "cancel": "Annuler",
      "noTitle": "(Sans titre)",
      "delete": "Supprimer",
      "deleteEvent": "Supprimer l'événement",
      "deleteMultipleEvent": "Supprimer plusieurs événements",
      "selectedItems": "Éléments sélectionnés",
      "deleteSeries": "Supprimer la série",
      "edit": "Modifier",
      "editSeries": "Modifier la série",
      "editEvent": "Modifier l'événement",
      "createEvent": "Créer",
      "subject": "Sujet",
      "addTitle": "Ajouter un titre",
      "moreDetails": "Plus de détails",
      "moreEvents": "Plus d'événements",
      "save": "Enregistrer",
      "editContent": "Voulez-vous modifier uniquement cet événement ou toute la série?",
      "deleteRecurrenceContent": "Voulez-vous supprimer uniquement cet événement ou toute la série?",
      "deleteContent": "Êtes-vous sûr de vouloir supprimer cet événement?",
      "deleteMultipleContent": "Êtes-vous sûr de vouloir supprimer les événements sélectionnés?",
      "newEvent": "Nouvel événement",
      "title": "Titre",
      "location": "Emplacement",
      "description": "Description",
      "timezone": "Fuseau horaire",
      "startTimezone": "Fuseau horaire de début",
      "endTimezone": "Fuseau horaire de fin",
      "repeat": "Répéter",
      "saveButton": "Enregistrer",
      "cancelButton": "Annuler",
      "deleteButton": "Supprimer",
      "recurrence": "Récurrence",
      "wrongPattern": "Le modèle de récurrence n'est pas valide.",
      "seriesChangeAlert": "Les modifications apportées à des instances spécifiques de cette série seront annulées et ces événements correspondront à nouveau à la série.",
      "createError": "La durée de l'événement doit être inférieure à la fréquence de récurrence. Réduisez la durée ou modifiez le modèle de récurrence dans l'éditeur d'événements récurrents.",
      "recurrenceDateValidation": "Certains mois ont moins de jours que la date sélectionnée. Pour ces mois, l'occurrence aura lieu le dernier jour du mois.",
      "sameDayAlert": "Deux occurrences du même événement ne peuvent pas avoir lieu le même jour.",
      "occurenceAlert": "Impossible de reprogrammer une occurrence de l'événement récurrent si elle passe à côté d'une occurrence ultérieure du même événement.",
      "editRecurrence": "Modifier la récurrence",
      "repeats": "Répétitions",
      "alert": "Alerte",
      "startEndError": "La date de fin sélectionnée se produit avant la date de début.",
      "invalidDateError": "La valeur de date entrée est invalide.",
      "blockAlert": "Les événements ne peuvent pas être programmés pendant la période bloquée.",
      "ok": "D'accord",
      "yes": "Oui",
      "no": "Non",
      "occurrence": "Occurrence",
      "series": "Série",
      "previous": "Précédent",
      "next": "Suivant",
      "timelineDay": "Jour de la chronologie",
      "timelineWeek": "Semaine de la chronologie",
      "timelineWorkWeek": "Semaine de travail de la chronologie",
      "timelineMonth": "Mois de la chronologie",
      "expandAllDaySection": "Développer",
      "collapseAllDaySection": "Réduire",
      "timelineYear": "Année de la chronologie",
      "editFollowingEvent": "Événements suivants",
      "deleteTitle": "Supprimer l'événement",
      "editTitle": "Modifier l'événement",
      "beginFrom": "Commence à partir de",
      "endAt": "Fin à",
      "searchTimezone": "Rechercher un fuseau horaire",
      "noRecords": "Aucun enregistrement trouvé",
    },
    "recurrenceeditor": {
      "none": "Aucune",
      "daily": "Quotidien",
      "weekly": "Hebdomadaire",
      "monthly": "Mensuel",
      "month": "Mois",
      "yearly": "Annuel",
      "never": "Jamais",
      "until": "Jusqu'à",
      "count": "Nombre",
      "first": "Premier",
      "second": "Deuxième",
      "third": "Troisième",
      "fourth": "Quatrième",
      "last": "Dernier",
      "repeat": "Répéter",
      "repeatEvery": "Répéter chaque",
      "on": "Répéter sur",
      "end": "Fin",
      "onDay": "Jour",
      "days": "Jour(s)",
      "weeks": "Semaine(s)",
      "months": "Mois",
      "years": "Année(s)",
      "every": "chaque",
      "summaryTimes": "fois",
      "summaryOn": "sur",
      "summaryUntil": "jusqu'à",
      "summaryRepeat": "Répétitions",
      "summaryDay": "jour(s)",
      "summaryWeek": "semaine(s)",
      "summaryMonth": "mois",
      "summaryYear": "année(s)"
    }
  }
});

const MonthCalendar = ({ onDateSelect }) => {
  const { t, i18n } = useTranslation(); // Use the translation hook

  const currentLocale = i18n.language === 'fr' ? 'fr' : 'en';
  const handlePopupOpen = (args) => {
    args.cancel = true;
  }
  const handleDateClick = (event) => {
    const selectedDate = event.startTime;
    onDateSelect(selectedDate);
  };

  return (
    <div className="month-calendar-container custom-style-month-cal">
      <ScheduleComponent
        width='100%'
        height='60%'
        selectedDate={new Date()}
        currentView='Month'
        eventSettings={{ dataSource: [] }} // No events in the small calendar
        // onClick={handleDateClick}
        locale={currentLocale}
        popupOpen={handlePopupOpen}
        cellClick={(args) => handleDateClick(args)}  // Trigger the handler on cell click
        firstDayOfWeek={1}
      >
        <ViewsDirective>
          <ViewDirective option='Month' />
        </ViewsDirective>
        <Inject services={[Month]} />
      </ScheduleComponent>
    </div>
  );
};

export default MonthCalendar;
