import axiosInstance from '../axiosConfig';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}collaboration-api/service-provider`;

const calendarService = {

  freeTimeSlots: async (accessToken, selectedLocations) => {
    // console.log(`form claender service ${locationId}`)
    try {
      const response = await axiosInstance.get(`${API_URL}/availability/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { locations: Array.isArray(selectedLocations) ? selectedLocations.join(',') : selectedLocations, }
      });
      return {
        data: response.data,
      };
    } catch (error) { 
      throw new Error("Calendar failed. Please check your credentials.");
    }
  },

  fetchSessions: async (accessToken, locationId, service) => {
    // console.log("from api location id is:", locationId, service);
    try {
      const response = await axios.get(`${API_URL}/session`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: {
          location: Array.isArray(locationId) ? locationId.join(',') : locationId, // Convert array to comma-separated string
          type: service
        }
      });
      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error("Calendar failed. Please check your credentials.");
    }
  },

}

export default calendarService;
