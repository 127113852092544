import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import {
    FaFileSignature,
    FaRegEye,
    FaEnvelopeOpenText,
    FaFileInvoiceDollar,
    FaPaperPlane,
    FaHamburger
} from "react-icons/fa";
import { IoDocuments } from "react-icons/io5";
import { toast } from "react-toastify";
import rdvSessionService from "services/rdvSessionService";
import { useTranslation } from "react-i18next";
import Utils from 'services/Utils';


const AttestationPaymentActions = ({ data, participant, selectedSession, onStateChange }) => {
    const { t } = useTranslation();
    const timeZoneInfo = Utils.getLocalTimeZoneInfo().timeZone;

    const handlePreview = (fileUrl) => {
        if (fileUrl) {
            window.open(fileUrl, '_blank');
        } else {
            toast.error(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {t("attestationPaymentActions.file_not_found")}
                </div>,
                {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "red",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                }
            );
        }
    };

    const handleGenerate = async (type) => {
        if (type === 'attestation') {
            if (selectedSession.status === 'Late Cancel') {
                toast.error(t("attestationPaymentActions.attestation_generation_failed_for_late_cancel"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "red",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
                return;
            }
            try {
                await rdvSessionService.generateAttestation(selectedSession.id, participant.id, timeZoneInfo);
                toast.success(t("attestationPaymentActions.attestation_generated_successfully"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "#FF6666",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
                onStateChange();
            } catch (error) {
                toast.error(t("attestationPaymentActions.attestation_generation_failed"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "red",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
            }
        }
        else if (type === 'payment') {
            if (!participant?.amount_per_session) {
                toast.error(t("attestationPaymentActions.payment_ticket_generation_failed_no_income_declared"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "#FF6666",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
                return;
            }
            try {
                await rdvSessionService.generatePaymentTicket(selectedSession.id, participant.id,timeZoneInfo);
                toast.success(t("attestationPaymentActions.payment_ticket_generated_successfully"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "#FF6666",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
                onStateChange();
            } catch (error) {
                toast.error(t("attestationPaymentActions.payment_ticket_generation_failed"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "red",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
            }
        }
    };

    const handleEmail = async (type) => {
        try {
            if (type === 'attestation') {
                await rdvSessionService.emailAttestation(selectedSession.id, participant.id);
                toast.success(t("attestationPaymentActions.attestation_emailed_successfully"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "#FF6666",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
            } else if (type === 'payment') {
                await rdvSessionService.emailPaymentTicket(selectedSession.id, participant.id);
                toast.success(t("attestationPaymentActions.payment_ticket_emailed_successfully"), {
                    position: "bottom-center",
                    style: {
                        backgroundColor: "#FF6666",
                        color: "white",
                        width: 'auto',
                        minWidth: '400px'
                    },
                    progressBar: false,
                    icon: false
                });
            }
        } catch (error) {
            toast.error(t(`attestationPaymentActions.${type}_email_failed`), {
                position: "bottom-center",
                style: {
                    backgroundColor: "red",
                    color: "white",
                    width: 'auto',
                    minWidth: '400px'
                },
                progressBar: false,
                icon: false
            });
        }
    };

    const participantRecord = selectedSession?.participant_records.find((obj) => obj.participant === participant.id);
    const attestationGenerated = participantRecord?.attestation_generated;
    const paymentGenerated = participantRecord?.payment_ticket_generated;

    // console.log("data", data);
    // console.log("selectedSession", selectedSession);

    return (
        <Menu>
            <MenuButton
                as={IconButton} icon={<IoDocuments />}
                variant="outline"
                aria-label="Document Actions"
                disabled={data.is_closed || !selectedSession?.participants.map(p => p.id).includes(participant.id) || ((selectedSession.status != 'Occurred'))}
            />
            <MenuList>
                {!attestationGenerated && (
                    <MenuItem
                        icon={<FaFileSignature size={18} />}
                        fontSize="lg"
                        paddingY={4}
                        onClick={() => handleGenerate('attestation')}>
                        {t("attestationPaymentActions.generate_attestation")}
                    </MenuItem>
                )}
                {attestationGenerated && (
                    <>
                        <MenuItem icon={<FaRegEye size={18} />}
                            fontSize="lg"
                            paddingY={4}
                            onClick={() => handlePreview(participantRecord?.attestation_file?.file)}>
                            {t("attestationPaymentActions.preview_attestation")}
                        </MenuItem>
                        <MenuItem icon={<FaEnvelopeOpenText size={18} />}
                            fontSize="lg"
                            paddingY={4}
                            onClick={() => handleEmail('attestation')}>
                            {t("attestationPaymentActions.email_attestation")}
                        </MenuItem>
                    </>
                )}
                {!paymentGenerated && selectedSession?.type != 'Info' && (
                    <MenuItem icon={<FaFileInvoiceDollar size={18} mt={4} />}
                        fontSize="lg"
                        paddingY={4}
                        onClick={() => handleGenerate('payment')}>
                        {t("attestationPaymentActions.generate_payment_ticket")}
                    </MenuItem>
                )}
                {paymentGenerated && (
                    <>
                        <MenuItem icon={<FaRegEye size={18} mt={4} />}
                            fontSize="lg"
                            paddingY={4}
                            onClick={() => handlePreview(participantRecord?.payment_ticket?.file)}>
                            {t("attestationPaymentActions.preview_payment_ticket")}
                        </MenuItem>
                        <MenuItem icon={<FaEnvelopeOpenText size={18} />}
                            fontSize="lg"
                            paddingY={4}
                            onClick={() => handleEmail('payment')}>
                            {t("attestationPaymentActions.email_payment_ticket")}
                        </MenuItem>
                    </>
                )}
            </MenuList>
        </Menu>
    );
};

export default AttestationPaymentActions;
