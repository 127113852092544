import React, { useEffect } from 'react';
import BrandHeader from '../../../components/menu/BrandHeader';
import CalendarMain from './components/CalendarMain';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { logout } from 'actions/authActions';
import { useHistory } from "react-router-dom";
import rdvService from 'services/rdvService';
import { useTranslation } from 'react-i18next';


const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  // Assuming accessToken is stored in Redux state
  const accessToken = useSelector((state) => state.auth.accessToken);
  // const accessToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI2MjA4NTAwLCJpYXQiOjE3MjYxMjIxMDAsImp0aSI6ImJiNjU5MDAzNmY4ZjQ1ZTk4NDQ5NGNhZmUyNzk4OWI2IiwidXNlcl9pZCI6N30._wrd5ONxDxcpulRvKAVgNH63feiqv-jtwXPrFAnd53M`
  let toastShown = false;
  useEffect(() => {
    const fetchStatisticsTodayAndWeek = async () => {
      try {
        const res = await rdvService.statistics(accessToken);

        if (res.error) {
          if (res.error.status === 401) {
            console.log("Unauthorized. Redirecting to sign-in.");

            if (!toastShown) {
              toast.error(`${t('session_expired_error_msg')}`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
              });
              toastShown = true; // Set the flag to true to prevent future toasts
            }
            setTimeout(() => {
              dispatch(logout());
              history.push('/auth/sign-in');
              // alert("loggedout")
            }, 2000);
          } else {
            console.log("Error fetching statistics:", res.error);

          }
        } else {
          // Process the response here, for example:
          const { sessions_today, sessions_this_week } = res.data;
          // console.log("Statistics fetched:", sessions_today, sessions_this_week);
        }
      } catch (err) {
        console.log("Error during fetch:", err);
        
      }
    };

    if (accessToken) {
      fetchStatisticsTodayAndWeek();
    }
  }, [accessToken, dispatch, history]);

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrandHeader />
      <CalendarMain />
    </div>
  );
};

export default Index;
