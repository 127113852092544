import React, { useEffect } from "react";
import { Flex, Input, Button, Select, Box } from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import FilterIcon from "../../../../assets/img/dashboards/filter_icon.svg";

const selectStyles = {
    backgroundColor: "rgba(86, 86, 86, 15%)",
    borderRadius: "12px",
    color: "gray.900",
    marginLeft: "10px",
};

const datePickerStyles = {
    ...selectStyles,
    padding: "8px 12px",
    border: "none",
    width: "150px",
    marginRight: "10px",
    _placeholder: { color: "black", fontSize: "" },
};

const FilterTransactions = ({
    filters,
    setFilters,
    searchType,
    setSearchType,
    searchQuery,
    setSearchQuery,
    handleSearch,
    handleResetSearchAndFilter,
}) => {
    const { t } = useTranslation();

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
        setSearchQuery('');
    };

    return (
        <>
            <Box marginY={"10px"} width={"100%"} border="1px" borderColor="gray.300" borderRight={"none"} paddingY={2}>
                <Flex alignItems={"center"} justifyContent="space-around">
                    <SearchIcon fontSize={"2xl"} marginX={4} onClick={handleSearch} />
                    <Select value={searchType} onChange={handleSearchTypeChange} width="auto" marginRight={4}>
                        <option value="participant_name">{t("admin_filters.names")}</option>
                        <option value="file_number">{t("admin_filters.file_numbers")}</option>
                    </Select>
                    <Input
                        placeholder={t("admin_filters.search")}
                        style={{ width: "77%" }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Box width={"10%"}></Box>
                </Flex>
            </Box>

            <Box width={"100%"} border="1px" borderColor="gray.300" borderRight={"none"} paddingY={2} borderTop={"none"}>
                <Flex alignItems="center">
                    <Box marginLeft={4}>
                        <img style={{ width: "30px", height: "30px" }} src={FilterIcon} alt="filter" />
                    </Box>
                    <Select
                        placeholder={t("placeholder.status")}
                        sx={selectStyles}
                        width={"auto"}
                        marginRight={4}
                        value={filters.status}
                        onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                    >
                        <option value="True">{t('transactions.paid')}</option>
                        <option value="False">{t('transactions.unpaid')}</option>
                        {/* <option value="Unpaid">{t('transactions.failed')}</option> */}
                    </Select>

                    <CloseIcon
                        mr={6}
                        ml={"auto"}
                        cursor="pointer"
                        onClick={handleResetSearchAndFilter}
                    />
                </Flex>
            </Box>
        </>
    );
};

export default FilterTransactions;
